/**
 * Header Component
 * Main navigation header for the application
 * Handles authentication UI, navigation, and responsive design
 */
import React, { useContext, useEffect, useRef} from "react";
import { useRouterContext, useLogin, useLogout, useTranslate } from "@refinedev/core";
import { ColorModeContext } from "contexts";
import { CredentialResponse } from "../../../interfaces/google";
import { useAppContext } from "contexts/AppContext";
import { Button } from "../../ui/button"
import NavItems from "./navItems"
import MobileNav from "./mobileNav"

export const Header: React.FC = () => {
  // Initialize login mutation with Google OAuth support
  const { mutate: login } = useLogin<CredentialResponse>({
    v3LegacyAuthProviderCompatible: true
  });

  // Get global state and user data from context
  const { loggedIn, setLoggedIn, currentUserData, currentTutorData } = useAppContext();
  
  // Initialize translation utility
  const t = useTranslate();
  
  // Initialize logout mutation
  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: true
  });

  /**
   * GoogleButton Component
   * Renders and manages Google Sign-In functionality
   */
  const GoogleButton = (): JSX.Element => {
    // Reference to the DOM element where Google button will be rendered
    const divRef = useRef<HTMLDivElement>(null);

    // Initialize Google Sign-In on component mount
    useEffect(() => {
      // Check for required dependencies
      if (typeof window === "undefined" || !window.google || !divRef.current) {
        return;
      }
      
      try {
        // Configure Google Sign-In
        window.google.accounts.id.initialize({
          ux_mode: "popup", // Display as popup window
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
          callback: async (res: CredentialResponse) => {
            // Handle successful authentication
            if (res.credential) {
              await login(res);
              setLoggedIn(true);
            }
          },
        });

        // Render the Google Sign-In button
        window.google.accounts.id.renderButton(divRef.current, {
          theme: "filled_blue",
          size: "medium",
          type: "standard",
        });
      } catch (error) {
        console.log(error);
      }
    }, []); 

    // Return container for Google button
    return <div ref={divRef} />;
  };

  // Get theme context for color mode
  const { mode, setMode } = useContext(ColorModeContext);

  // Get routing utilities
  const { Link } = useRouterContext();

  // Always render header for theme toggle functionality
  const shouldRenderHeader = true;

  return shouldRenderHeader ? ( 
    // Main header container
    <header className="w-full border-b" style={{ backgroundColor: '#090e2b' }}>
      <div className="wrapper flex items-center justify-between">
        {/* Logo and homepage link */}
        <a href="/" className="w-36">
          <img 
            src="/assets/images/sc.png" 
            width={128} 
            height={38}
            alt="Evently logo" 
          />
        </a>

        {/* Desktop Navigation Menu */}
        <nav className="ml-20 hidden md:flex-between w-full justify-center md:flex">
          <NavItems />
        </nav>
    
        {/* Mobile Navigation Menu */}
        <div className="flex w-32 justify-end gap-3">
          <MobileNav /> 
        </div>
      </div>
    </header>
  ) : null;
};