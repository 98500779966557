/**
 * Button Component
 * Customizable button component with various styles and variants
 * Built using Radix UI Slot primitive and class-variance-authority
 */
import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "../../utils/cn"

/**
 * Button variants configuration using class-variance-authority
 * Defines different styles and sizes for the button component
 */
const buttonVariants = cva(
  // Base button styles
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      // Visual variants for different button types
      variant: {
        // Default primary button style
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        // Destructive action button style
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        // Outlined button style
        outline:
          "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        // Secondary button style
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        // Ghost button style (transparent background)
        ghost: "hover:bg-accent hover:text-accent-foreground",
        // Link style button
        link: "text-primary underline-offset-4 hover:underline",
      },
      // Size variants for different button scales
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    // Default variant settings
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

/**
 * ButtonProps interface
 * Extends HTML button props with additional custom properties
 */
export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean  // Flag to render button as a child component
}

/**
 * Button Component
 * @param className - Additional CSS classes
 * @param variant - Button style variant
 * @param size - Button size variant
 * @param asChild - Whether to render as child component
 * @param props - Additional button props
 * @param ref - Reference to button element
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    // Use Slot component if asChild is true, otherwise use button element
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)

// Set display name for debugging purposes
Button.displayName = "Button"

export { Button, buttonVariants }