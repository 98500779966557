/**
 * AppContext
 * Global state management for the application using React Context
 * Manages user authentication, profile data, and device type detection
 */
import React, { createContext, useContext, useState } from 'react';
import { TutorProfileProps, StudentProfileProps, UserProfileProps } from 'interfaces/common';

// Define the shape of the context's state and methods
interface AppContextProps {
  // Current student user's data including profile and educational information
  currentStudentData: any;
  
  // Current tutor user's data including subjects, schedule, and ratings
  currentTutorData: any;
  
  // Basic user data common to both students and tutors
  currentUserData: any;
  
  // Authentication state tracking
  loggedIn: boolean;
  
  // Device type detection for responsive design
  isMobile: boolean;
  
  // Methods to update student data
  setCurrentStudentData: (data: StudentProfileProps | null) => void;
  
  // Methods to update tutor data
  setCurrentTutorData: (data: TutorProfileProps | null) => void;
  
  // Methods to update basic user data
  setCurrentUserData: (data: UserProfileProps | null) => void;
  
  // Method to update authentication state
  setLoggedIn: (data: boolean) => void;
  
  // Method to update device type
  setIsMobile: (data: boolean) => void;
}

// Create context with default values
// All setter functions are initialized as empty functions to satisfy TypeScript
const AppContext = createContext<AppContextProps>({
  currentStudentData: null,
  currentTutorData: null,
  currentUserData: null,
  loggedIn: false,
  isMobile: false,
  setCurrentStudentData: (data: any) => {},
  setCurrentTutorData: (data: any) => {},
  setCurrentUserData: (data: any) => {},
  setLoggedIn: (data: boolean) => {},
  setIsMobile: (data: boolean) => {}
});

/**
 * AppProvider Component
 * Wraps the application and provides global state management
 * @param children - Child components that will have access to the context
 */
export const AppProvider = ({ children }: any) => {
  // Initialize application state with useState hook
  // All data is initially null and flags are false
  const [state, setState] = useState({
    currentStudentData: null,
    currentTutorData: null,
    currentUserData: null,
    loggedIn: false,
    isMobile: false,
  });

  /**
   * Updates student data in the global state
   * Maintains immutability by spreading previous state
   * @param data - New student data to be stored
   */
  const setCurrentStudentData = (data: any) => {
    setState((prevState) => ({
      ...prevState,
      currentStudentData: data,
    }));
  };

  /**
   * Updates tutor data in the global state
   * @param data - New tutor data to be stored
   */
  const setCurrentTutorData = (data: any) => {
    setState((prevState) => ({
      ...prevState,
      currentTutorData: data,
    }));
  };

  /**
   * Updates basic user data in the global state
   * @param data - New user data to be stored
   */
  const setCurrentUserData = (data: any) => {
    setState((prevState) => ({
      ...prevState,
      currentUserData: data,
    }));
  };

  /**
   * Updates authentication state
   * @param data - New authentication state
   */
  const setLoggedIn = (data: boolean) => {
    setState((prevState) => ({
      ...prevState,
      loggedIn: data,
    }));
  }

  /**
   * Updates device type detection state
   * @param data - New device type state
   */
  const setIsMobile = (data: boolean) => {
    setState((prevState) => ({
      ...prevState,
      isMobile: data,
    }));
  }

  // Provide the state and setter functions to all child components
  return (
    <AppContext.Provider value={{ 
      ...state, 
      setCurrentStudentData, 
      setCurrentTutorData, 
      setCurrentUserData, 
      setLoggedIn, 
      setIsMobile 
    }}>
      {children}
    </AppContext.Provider>
  );
};

/**
 * Custom hook for consuming AppContext
 * Provides easy access to global state throughout the application
 * @returns AppContext state and methods
 */
export const useAppContext = () => useContext(AppContext);