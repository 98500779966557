import { ProfileProps } from "interfaces/common";
import { HistoryEdu } from "@mui/icons-material";
import SelectSubject from "components/tutor/SelectSubject";
import Balance from "./Balance"

function checkImage(url: any) {
  const img = new Image();
  img.src = url;
  return img.width !== 0 && img.height !== 0;
}
var balance = -12.13; //Yeah this is just for testing purposes until we implement it

const Profile = ({ type, firstName, lastName, avatar, email, subjects, description, rating, handleToggle, isActive, isTutor, tutorToBeBooked, balance }: ProfileProps) => (
  <div>
    <h1 className="text-3xl font-semibold mb-4 text-center underline">{type} Profile</h1>
    <div className="rounded-md p-4 bg-gray-100">
      <div className="flex flex-col md:flex-row items-center md:items-start gap-4">
        <img
          src={
            checkImage(avatar)
              ? avatar
              : "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
          }
          width={300}
          height={300}
          alt="abstract"
          className="my_profile-bg"
        />
        <div className="md:mt-8 w-full">
          <div className="flex flex-col gap-2">
            <h2 className="text-2xl font-semibold text-gray-800">
              {firstName} {lastName}
            </h2>
            <p className="text-sm text-gray-600">{isTutor ? description : "Student"}</p>
            {isTutor && type === "My Tutor" && (
              <div className="flex items-center gap-4">
                <label className="text-sm text-gray-600">Set your Active Status</label>
                <input
                  type="checkbox"
                  checked={isActive}
                  //@ts-ignore
                  onChange={(e) => handleToggle(e, !isActive)}
                />
              </div>
            )}
            {!isTutor && <Balance balance={balance} />}
          </div>
          {isTutor && (
            <div className="flex flex-col gap-4 mt-4">
              <div className="flex items-center gap-2">
                <HistoryEdu className="text-blue-900" />
                <p className="text-sm text-gray-600 font-semibold">Level of Education: Bachelors Degree</p>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-sm text-gray-600 font-semibold">Subjects:</p>
                <div className="flex flex-col gap-1">
                  {subjects ? (
                    subjects.map((subject, index) => (
                      <p key={index} className="text-sm text-gray-600">{subject.name} - ${subject.price}</p>
                    ))
                  ) : (
                    <p className="text-sm text-gray-600">No subjects available</p>
                  )}
                </div>
                {tutorToBeBooked ? (
                  <div className="w-full md:w-1/2"> {/* Adjust the width as needed */}
                    <SelectSubject tutor={tutorToBeBooked} />
                  </div>
                ) : null}
                
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default Profile;
