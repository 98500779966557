import React, { useState, useEffect } from 'react';
import { BookingFormProps } from 'interfaces/common';
import CustomButton from './CustomButton';
import { Subject } from 'interfaces/tutor';
import { ScheduleMeeting } from 'react-schedule-meeting';

const BookingForm = ({
  type,
  tutorInfo,
  register,
  handleSubmit,
  formLoading,
  onFinishHandler,
  isImmediate,
  subject,
}: BookingFormProps) => {
  const [dateFieldValue, setDateFieldValue] = useState('');
  const [startTimeFieldValue, setStartTimeFieldValue] = useState('');
  const [duration, setDuration] = useState('');
  const [selectedSubject, setSelectedSubject] = useState(subject);
  const subjects = tutorInfo.subjects;

  useEffect(() => {
    const date = new Date();
    
    if (isImmediate) {
      const defaultBookingData = {
        subject: { price: subjects[0].price, name: subjects[0].name },
        date: date?.toISOString().split('T')[0],
        startTime: date?.toTimeString().split(' ')[0],
        duration: '01:00:00',
      };
      console.log('defaultBookingData:', defaultBookingData);
      onFinishHandler(defaultBookingData);
    }
  }, [isImmediate]);

  let tutorSchedule;
  //const availableTimeSlots = tutorInfo.schedule;
  //Grab schedule from tutor
  if (JSON.stringify(tutorInfo?.schedule) !== JSON.stringify([{}])){
      tutorSchedule = tutorInfo.schedule;
  }
  else{ // If doesn't exist, create our own for testing
      tutorSchedule = [0, 1].map((id) => {
          return {
            id,
            startTime: new Date(),
            endTime: new Date(),
          };
        });
  }

  const handleStartTimeSelect = (data: any) => {
    setDateFieldValue(formatDate(data.startTime));
    setStartTimeFieldValue(data.startTime.toTimeString().split(' ')[0]);
    console.log('new click', data, dateFieldValue, startTimeFieldValue);
  };

  const handleDurationChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const duration = event.target.value as string;
    setDuration(duration);
  }

  const handleSubjectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedSubject = event.target.value;
    const foundSubject = tutorInfo.subjects.find((subject) => subject.name === newSelectedSubject);
    if (foundSubject) {
      setSelectedSubject(foundSubject);
    } else {
      // Handle the case where the subject with the specified name was not found
      // For example, you might want to set a default subject or display an error message
    }
    //setSelectedSubject(subject);
  }

  const convertDurationToHours = (duration: string) => {
    const [hours, minutes, seconds] = duration.split(':').map(Number);
    return hours + minutes / 60 + seconds / 3600;
  };

  function formatDate(date: any) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();
  
  const bookingData = {
    subject: selectedSubject,
    date: dateFieldValue,
    startTime: startTimeFieldValue,
    duration,
  };

  onFinishHandler(bookingData);
};


  const availableTimeslots = [0, 1, 2, 3, 4, 5].map((id) => {
    return {
      id,
      startTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(9, 0, 0, 0)),
      endTime: new Date(new Date(new Date().setDate(new Date().getDate() + id)).setHours(17, 0, 0, 0)),
    };
  });

  return (
    <div>
      <h1 className="text-5xl font-bold text-center mb-10"> Schedule A Session! </h1>
      <div className="rounded-md p-8 bg-gray-100">
  <div className="flex gap-4 items-center justify-center">
    {/* On desktop, two separate boxes */}
    <div className="border border-gray-300 p-4 rounded-md flex-grow">
      <h2 className="text-xl font-bold">Select Date:</h2>
    </div>

    <div className="border border-gray-300 p-4 rounded-md flex-grow">
      <h2 className="text-xl font-bold">Select Start Time:</h2>
    </div>
  </div>

  <ScheduleMeeting
    borderRadius={10}
    primaryColor="#3f5b85"
    eventDurationInMinutes={0}
    availableTimeslots={tutorSchedule}
    onStartTimeSelect={handleStartTimeSelect}
    eventStartTimeSpreadInMinutes={30}
  />

  <form className="mt-8 space-y-4" onSubmit={onSubmit}>
    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
      <div className="w-full md:w-1/2 flex flex-col">
        <label className="text-lg font-bold mb-2">Select Subject:</label>
        <select
          value={selectedSubject.name}
          onChange={handleSubjectChange}
          className="mt-2 md:w-56 lg:w-52 border border-gray-300 rounded-md px-2 text-lg md:text-xl lg:text-2xl"
        >
          <option value=""></option>
          {subjects.map((subject: Subject) => (
            <option key={subject.name} value={subject.name}>
              {subject.name}
            </option>
          ))}
        </select>
        <p className="mt-2 text-lg">Hourly rate $ {selectedSubject.price}</p>
      </div>

      <div className="w-full md:w-1/2">
        <label className="text-lg font-bold mb-2">Date</label>
        <input
          type="text"
          className="w-full border border-gray-300 rounded-md px-3 py-2"
          value={dateFieldValue}
          readOnly
        />
      </div>

      <div className="w-full md:w-1/2">
        <label className="text-lg font-bold mb-2">Start Time</label>
        <input
          type="text"
          className="w-full border border-gray-300 rounded-md px-3 py-2"
          value={startTimeFieldValue}
          readOnly
        />
      </div>

      <div className="w-full md:w-1/2">
        <label className="text-lg font-bold mb-2">Duration</label>
        <select
          value={duration}
          onChange={handleDurationChange}
          className="w-full border border-gray-300 rounded-md px-3 py-2"
        >
          <option value="00:02:00">2 min</option>
          <option value="00:30:00">30 min</option>
          <option value="01:00:00">1 hour</option>
          <option value="01:30:00">1 hour 30 min</option>
          <option value="02:00:00">2 hour</option>
        </select>
      </div>
    </div>

    <div className="flex items-center justify-center mt-8">
      <CustomButton
        type="submit"
        title={formLoading ? 'Confirming...' : 'Continue to Payment'}
        backgroundColor="#475be8"
        color="#fcfcfc"
      />
    </div>
  </form>
</div>


      <div className="w-full p-4">
    <div className="bg-white rounded-md shadow-md overflow-hidden">
        <div className="p-4">
            <h2 className="text-xl font-semibold text-blue-900 text-center">
                Booking Preview
            </h2>
            <div className="mt-4">
                <p className="text-sm text-gray-600 mb-2">Tutor: {tutorInfo.User.firstName} {tutorInfo.User.lastName}</p>
                <p className="text-sm text-gray-600 mb-2">Subject: {selectedSubject.name}</p>
                <p className="text-sm text-gray-600 mb-2">Price: ${Math.round(parseFloat(selectedSubject.price) * convertDurationToHours(duration))}</p>
                <p className="text-sm text-gray-600 mb-2">Date: {dateFieldValue}</p>
                <p className="text-sm text-gray-600 mb-2">Time: {startTimeFieldValue}</p>
                <p className="text-sm text-gray-600">Duration: {duration}</p>
            </div>
        </div>
    </div>
</div>


    </div>
  );
};

export default BookingForm;
