//@ts-nocheck
import { useList } from "@refinedev/core";
import { Box, Checkbox, Tooltip } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import SelectSubject from 'components/tutor/SelectSubject';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { TutorCard } from 'components';

// Replace Typography and Stack with regular HTML elements
const Tutors = () => {
  console.log('book a tutor');

  const { data: tutorsData, isLoading: loadingTutors, isError: tutorsError } = useList({
    resource: 'tutors',
  });

  const { data: subjectsData } = useList({
    resource: 'subjects',
  });

  const [filteredSubject, setFilteredSubject] = useState('all');
  const [selectedDropdown, setSelectedDropdown] = useState('Reccomended');
  const [activeChecked, setActiveChecked] = useState(false);

  const allTutors = tutorsData?.data.map((item) => ({
    ...item,
    schedule: JSON.parse(item.schedule),
    subjects: JSON.parse(item.subjects),
  })) ?? [];

  const subjects = subjectsData?.data ?? [];

  const filteredTutors = allTutors.filter((tutor) => {
    const matchesSubject =
        filteredSubject === 'all' ||
        tutor.subjects.some(
            (subject: { name: string }) => filteredSubject.includes(subject.name),
        );

    const matchesIsActive = activeChecked ? tutor.isActive : true;

    return matchesSubject && matchesIsActive;
});


  console.log('All tutors are ', allTutors);

  if (loadingTutors) return <div>Loading...</div>;
  if (tutorsError) return <div>Error...</div>;

  const handleChange = (newSelectedSubject: any) => {
    setFilteredSubject(newSelectedSubject.target.value);
  };

  const handleChangeDropdown = (newDrop: any) => {
    setSelectedDropdown(newDrop.target.value);
  };

  const handleToggle = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setActiveChecked(checked);
  };

  return (
    <div className="container mx-auto px-4 max-w-screen-xl">
      <h1 className="text-4xl font-bold text-center mb-6">Book A Tutor</h1>
      <p className="text-2xl font-bold text-center mb-6">Find the tutor that is the best fit for you!</p>
      <div className="w-3/4 mx-auto mb-8 bg-gray-100 p-6 rounded-md">
        <div className="flex flex-wrap items-center gap-4">
          <label className="text-lg">Search By Subject:</label>
          <select
            value={filteredSubject}
            onChange={handleChange}
            className="p-2 border rounded-md"
          >
            <option value="all">All</option>
            {subjects.map((sub) => (
              <option key={sub.id} value={sub.name}>
                {sub.name}
              </option>
            ))}
          </select>

          <label className="text-lg">Sort By:</label>
          <select
            value={selectedDropdown}
            onChange={handleChangeDropdown}
            className="p-2 border rounded-md"
          >
            <option value="Reccomended">Reccomended</option>
            <option value="LowestPrice">Lowest Price</option>
            <option value="HighestRating">Highest Rating</option>
          </select>

          <label className="text-lg">Active Tutors</label>
          <input
            type="checkbox"
            checked={activeChecked}
            onChange={(e) => handleToggle(e, e.target.checked)}
            className="h-6 w-6"
          />

          <Tooltip title="Looking for Immediate Help? Check the box to find currently active tutors." arrow>
            <HelpOutlineIcon className="text-gray-800" />
          </Tooltip>
        </div>
      </div>

      <div className="flex flex-col items-center space-y-8"> {/* Added space-y-4 for vertical spacing */}
        {filteredTutors.map((tutor) => (
          <div key={tutor._id} className="w-full"> {/* Removed margin class */}
            <TutorCard
              id={tutor.UserId}
              firstName={tutor.User.firstName}
              lastName={tutor.User.lastName}
              email={tutor.email}
              subjects={tutor.subjects}
              avatar={tutor.User.avatar}
              description={tutor.tutorDescription}
              isActive={tutor.isActive}
              tutor={tutor}
              rating={tutor.rating}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tutors;
