interface SmartGoalCardProps {
  color: string;
  letter: string;
  title: string;
  description: string;
}

interface CardProps {
  className?: string;
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ className = "", children }) => (
  <div className={`bg-white rounded-lg shadow-lg ${className}`}>
    {children}
  </div>
);

const SmartGoalCard: React.FC<SmartGoalCardProps> = ({ color, letter, title, description }) => {
  const getColorClasses = (baseColor: string): string => {
    switch(baseColor) {
      case 'red':
        return 'bg-red-100 hover:bg-red-50';
      case 'orange':
        return 'bg-orange-100 hover:bg-orange-50';
      case 'green':
        return 'bg-green-100 hover:bg-green-50';
      case 'blue':
        return 'bg-blue-100 hover:bg-blue-50';
      case 'purple':
        return 'bg-purple-100 hover:bg-purple-50';
      default:
        return 'bg-gray-100 hover:bg-gray-50';
    }
  };

  const getIconColorClass = (baseColor: string): string => {
    switch(baseColor) {
      case 'red':
        return 'bg-red-500';
      case 'orange':
        return 'bg-orange-500';
      case 'green':
        return 'bg-green-500';
      case 'blue':
        return 'bg-blue-500';
      case 'purple':
        return 'bg-purple-500';
      default:
        return 'bg-gray-500';
    }
  };

  return (
    <div className={`rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300 ${getColorClasses(color)}`}>
      <div className="flex items-center gap-4 mb-4">
        <div className={`${getIconColorClass(color)} text-white text-2xl font-bold w-12 h-12 rounded-full flex items-center justify-center`}>
          {letter}
        </div>
        <h3 className="text-gray-800 text-xl font-bold">{title}</h3>
      </div>
      <p className="text-gray-700">{description}</p>
    </div>
  );
};

const OurApproach: React.FC = () => {
  const smartGoals = [
    {
      letter: 'S',
      title: 'Specific',
      color: 'red',
      description: 'We set clear and specific milestones for student development goals, defining exactly what they need to achieve.'
    },
    {
      letter: 'M',
      title: 'Measurable',
      color: 'orange',
      description: 'Progress is tracked through defined targets and metrics, ensuring we can measure advancement towards goals.'
    },
    {
      letter: 'A',
      title: 'Achievable',
      color: 'green',
      description: 'Goals are challenging yet attainable, supported by the right resources and techniques for success.'
    },
    {
      letter: 'R',
      title: 'Realistic',
      color: 'blue',
      description: 'We ensure goals and success criteria are realistic and align with student abilities and circumstances.'
    },
    {
      letter: 'T',
      title: 'Time-Bound',
      color: 'purple',
      description: 'Clear timeframes are set for achieving goals, maintaining momentum and focus throughout the learning journey.'
    }
  ];

  return (
    <div className="max-w-6xl mx-auto px-4 py-12">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Our SMART Goals Approach</h1>
        <p className="text-xl text-gray-600 max-w-3xl mx-auto">
          We believe in setting structured, achievable goals for every student success. 
          Our tutors use the SMART framework to create an effective learning journey.
        </p>
      </div>

      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {smartGoals.map((goal) => (
          <SmartGoalCard
            key={goal.letter}
            letter={goal.letter}
            title={goal.title}
            color={goal.color}
            description={goal.description}
          />
        ))}
      </div>

      <div className="mt-16 bg-gray-50 rounded-xl p-8">
        <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">How We Implement SMART Goals</h2>
        <div className="grid md:grid-cols-2 gap-8">
          <Card className="p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-4">Initial Assessment</h3>
            <ul className="space-y-3 text-gray-700">
              <li>• Evaluate current knowledge and skills</li>
              <li>• Identify areas for improvement</li>
              <li>• Set baseline measurements</li>
              <li>• Define personalized learning objectives</li>
            </ul>
          </Card>
          <Card className="p-6">
            <h3 className="text-xl font-bold text-gray-800 mb-4">Progress Tracking</h3>
            <ul className="space-y-3 text-gray-700">
              <li>• Regular progress assessments</li>
              <li>• Adaptive learning paths</li>
              <li>• Performance analytics</li>
              <li>• Goal adjustment as needed</li>
            </ul>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default OurApproach;