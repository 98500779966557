import React, { useState } from 'react';

function Accordion({ title, answer }: { title: any; answer: any }) {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="py-2">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className="flex justify-between w-full"
      >
        <span className="font-semibold text-lg">{title}</span>
        <svg
          className={`fill-current h-8 w-8 transition-transform duration-300 ${
            accordionOpen ? 'transform rotate-180' : ''
          }`}
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          {accordionOpen ? (
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.293 5.293a1 1 0 011.414 0L10 9.586l4.293-4.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414z"
            />
          ) : (
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10 6a1 1 0 011 1v7.586l3.293-3.293a1 1 0 111.414 1.414l-5 5a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L9 14.586V7a1 1 0 011-1z"
            />
          )}
        </svg>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out ${
          accordionOpen ? 'opacity-100' : 'opacity-0 h-0'
        }`}
      >
        <div onClick={() => setAccordionOpen(!accordionOpen)} className={`${accordionOpen ? 'block' : 'hidden'} text-base cursor-pointer`}>{answer}</div>
      </div>
    </div>
  );
}

export default Accordion;
