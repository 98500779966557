/**
 * Title Component
 * Renders application title/logo in header or sidebar
 * Supports both collapsed and expanded states
 */
import React from "react";
import { useRouterContext, TitleProps } from "@refinedev/core";
import { Button } from "@mui/material";

import { stemconnects, stemconnects_logo } from 'assets';

export const Title: React.FC<TitleProps> = ({ 
    // collapsed prop determines if title is in minimized state
    collapsed 
}) => {
    // Get Link component from router context
    const { Link } = useRouterContext();

    return (
        <Button 
            // Button takes full width of container
            fullWidth 
            // Remove ripple effect from button
            variant="text" 
            disableRipple
        >
            <Link to="/">
                {collapsed ? (
                    // Show small logo when collapsed
                    <img 
                        src={stemconnects_logo} 
                        alt="Refine" 
                        width="28px" 
                    />
                ) : (
                    // Show full logo when expanded
                    <img 
                        src={stemconnects} 
                        alt="Refine" 
                        width="140px"
                    />
                )}
            </Link>
        </Button>
    );
};