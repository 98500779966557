// React application entry point

import React from "react";
import { createRoot } from "react-dom/client";

// Performance monitoring utility
import reportWebVitals from "./reportWebVitals";

// Main App component
import App from "./App";

// Global state provider
import { AppProvider } from './contexts/AppContext';

// Get root DOM element for rendering
const container = document.getElementById("root") as HTMLElement;

// Create React root using createRoot (React 18 concurrent mode)
const root = createRoot(container);

// Render the application
// Wrapped with AppProvider for global state management
// React.StrictMode is currently commented out
root.render(
  //<React.StrictMode>
  <AppProvider>
    <App />
  </AppProvider>
  //</React.StrictMode>
);

// Set up performance tracking
// Can send performance metrics to analytics endpoint
reportWebVitals();