//@ts-nocheck
import React from 'react';
import { Button } from '../components/ui/button'
import { useList } from "@refinedev/core";
import { Application } from 'components';

const AdminDashboard = () => {
    const [current, setCurrent] = React.useState(1);
    const pageSize = 2; // Number of items per page

    const { data: applicationsData, isLoading, isError } = useList({
        resource: 'tutor-application',
        pagination: {
            current,
            pageSize,
        },
    });

    console.log("data is" , applicationsData)

    const allApplications = applicationsData?.data.map((item) => ({
        ...item,
        subjects: JSON.parse(item.subjects),
    })) ?? [];

    const total = applicationsData?.total ?? 0;
    const pageCount = Math.ceil(total / pageSize);

    const handlePageChange = (newPage: number) => {
        setCurrent(newPage);
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="max-w-4xl mx-auto">
                {isLoading ? (
                    <div className="flex justify-center">
                        <span>Loading...</span>
                    </div>
                ) : (
                    <>
                        <div className="flex flex-col items-center space-y-6">
                            {allApplications.map((application) => (
                                <div key={application._id} className="w-full sm:w-[95%] md:w-[90%] lg:w-[85%]">
                                    <Application
                                        id={application._id}
                                        number={application.number}
                                        description={application.description}
                                        degree={application.degree}
                                        subjects={application.subjects}
                                        country={application.country}
                                        approved={application.approved}
                                    />
                                </div>
                            ))}
                        </div>

                        {/* Custom Pagination */}
                        <div className="mt-8 flex justify-center items-center gap-2">
                            <button
                                onClick={() => handlePageChange(current - 1)}
                                disabled={current === 1}
                                className={`px-3 py-1 rounded-md ${
                                    current === 1
                                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                                        : 'bg-blue-500 text-white hover:bg-blue-600'
                                }`}
                            >
                                Previous
                            </button>

                            {/* Page Numbers */}
                            {Array.from({ length: pageCount }, (_, i) => i + 1)
                                .filter(page => {
                                    if (page === 1 || page === pageCount) return true;
                                    if (page >= current - 1 && page <= current + 1) return true;
                                    return false;
                                })
                                .map((page, index, array) => (
                                    <React.Fragment key={page}>
                                        {index > 0 && array[index - 1] !== page - 1 && (
                                            <span className="px-2">...</span>
                                        )}
                                        <button
                                            onClick={() => handlePageChange(page)}
                                            className={`px-3 py-1 rounded-md ${
                                                current === page
                                                    ? 'bg-blue-500 text-white'
                                                    : 'bg-gray-200 hover:bg-gray-300'
                                            }`}
                                        >
                                            {page}
                                        </button>
                                    </React.Fragment>
                                ))}

                            <button
                                onClick={() => handlePageChange(current + 1)}
                                disabled={current === pageCount}
                                className={`px-3 py-1 rounded-md ${
                                    current === pageCount
                                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                                        : 'bg-blue-500 text-white hover:bg-blue-600'
                                }`}
                            >
                                Next
                            </button>
                        </div>

                        {/* Results Counter */}
                        <div className="text-center mt-4 text-sm text-gray-600">
                            Showing {((current - 1) * pageSize) + 1} to {Math.min(current * pageSize, total)} of {total} results
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default AdminDashboard;