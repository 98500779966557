/**
 * NavItems Component
 * Dynamic navigation menu that adapts based on user role
 * Handles navigation links, user authentication, and responsive design
 */
'use client';

// Import navigation configurations and necessary hooks
import { studentLinks, tutorLinks, visitorLinks, adminLinks } from '../../../constants'
import { useLogout, useRouterContext, useTranslate } from "@refinedev/core";
import { useNavigate, useLocation } from 'react-router-dom'
import React from 'react'
import { Logout } from '@mui/icons-material';
import { useAppContext } from 'contexts/AppContext';

const NavItems = () => {
  // Get routing utilities from Refine
  const { Link } = useRouterContext();

  // Initialize logout mutation with legacy auth compatibility
  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: true
  });

  // Initialize translation utility
  const t = useTranslate();

  // Get user data and authentication state from global context
  const { loggedIn, setLoggedIn, currentUserData, currentTutorData } = useAppContext();

  // Determine user role and set appropriate navigation links
  // Check if user is admin based on email
  const isAdmin = currentUserData?.email === "info@stemconnects.com" ? true : false;
  
  // Check if user is a tutor based on tutor data existence
  const isTutor = currentTutorData !== null;
  
  // Select appropriate navigation links based on user role and auth status
  const linksToUse = loggedIn
    ? isAdmin
      ? adminLinks      // Admin navigation links
      : isTutor
        ? tutorLinks    // Tutor navigation links
        : studentLinks  // Student navigation links
    : visitorLinks;     // Visitor navigation links

  // Initialize navigation utilities
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Handles user logout
   * Triggers logout mutation and updates global state
   */
  const handleLogout = () => {
    mutateLogout();
    setLoggedIn(false)
  }

  return (
    // Navigation list container with responsive styling
    <ul className="flex md:flex-row items-center justify-center gap-5">
      {/* Map through navigation links based on user role */}
      {linksToUse.map((link) => {
        // Check if current route matches link route
        const isActive = location.pathname === link.route;

        return (
          <li
            key={link.route}
            className={`${
              isActive && 'text-white'  // Highlight active link
            } flex-center p-medium-16 whitespace-nowrap`}
          >
            {/* Navigation link with consistent styling */}
            <a href={link.route} className="text-white">{link.label}</a>
          </li>
        );
      })}

      {/* User Profile Section - Only shown when logged in */}
      {loggedIn ? 
        <>
          {/* Display user name if available */}
          {currentUserData?.name && (
            <p className="text-sm text-white font-semibold">
              {currentUserData?.name}
            </p>
          )}

          {/* Display user avatar if available */}
          {currentUserData?.avatar && (
            <img
              src={currentUserData?.avatar}
              alt={currentUserData?.name}
              className="w-8 h-8 rounded-full"
            />
          )}

          {/* Logout Button */}
          <button
            key="logout"
            onClick={() => handleLogout()}
            className="flex items-center p-medium-16 whitespace-nowrap cursor-pointer"
          >
            <div className="flex items-center justify-center text-white">
              <div>
                <Logout/>
              </div>
            </div>
          </button> 
        </> 
      : null}
    </ul>
  );
}

export default NavItems;