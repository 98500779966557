import { useRouterContext, useUpdate } from "@refinedev/core";
import { Profile, Sessions, StudentSettings } from 'components';
import { useAppContext } from 'contexts/AppContext';
import { useState } from 'react';
import { Button } from 'components/ui/button';

const StudentDashboard = () => {
  // Router context for navigation
  const { Link } = useRouterContext();
  
  // State management for dashboard sections
  const [showStudentProfile, setShowStudentProfile] = useState(true);
  const [showSessions, setShowSessions] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  // Get current student data from app context
  const { currentStudentData } = useAppContext();
  const { mutate } = useUpdate();

  // Log current student data
  console.log({currentStudentData});

  // Component for rendering student profile
  function StudentProfile() {
    return (
      <Profile
        type="My Student"
        firstName={currentStudentData?.User?.firstName}
        lastName={currentStudentData?.User?.lastName}
        email={currentStudentData?.User?.email}
        avatar={currentStudentData?.User?.avatar}
        balance={currentStudentData?.balance}
        isTutor={false}
      />
    );
  }

  return (
    <div className="container mx-auto px-4 max-w-screen-xl">
      {/* Navigation bar for dashboard sections */}
      <nav className="bg-green-900 p-4 flex items-center">
        <div className="flex justify-between w-full">
          <div className="flex space-x-4 items-center mx-auto">
            {/* Profile Section Button */}
            <Button
              variant="outline"
              size="default"
              className="bg-primary/20 text-primary-foreground hover:bg-primary/30"
              onClick={() => {
                // Show profile, hide other sections
                setShowStudentProfile(true);
                setShowSettings(false);
                setShowSessions(false);
              }}
              disabled={false}
            >
              My Profile
            </Button>
            
            {/* Sessions Section Button */}
            <Button
              variant="outline"
              size="default"
              className="bg-primary/20 text-primary-foreground hover:bg-primary/30"
              onClick={() => {
                // Show sessions, hide other sections
                setShowSessions(true);
                setShowSettings(false);
                setShowStudentProfile(false);
              }}
              disabled={false}
            >
              Sessions
            </Button>

            {/* Settings Section Button */}
            <Button
              variant="outline"
              size="default"
              className="bg-primary/20 text-primary-foreground hover:bg-primary/30"
              onClick={() => {
                // Show settings, hide other sections
                setShowSettings(true);
                setShowSessions(false);
                setShowStudentProfile(false);
              }}
              disabled={false}
            >
              Settings
            </Button>
          </div>
        </div>
      </nav>

      <br />

      {/* Conditional rendering of dashboard sections */}
      {showStudentProfile && <StudentProfile />}
      {showSessions && <Sessions />}
      {showSettings && <StudentSettings />}
    </div>
  );
};

export default StudentDashboard;