/**
 * TutorCard Component
 * Displays comprehensive tutor information in a card format
 * Includes profile details, subject selection, and booking options
 */
import { Link, useNavigate } from "react-router-dom";
import { TutorCardProp, Subject } from 'interfaces/tutor';
import { useAppContext } from 'contexts/AppContext';
import { Button } from 'components/ui/button';
import { useState } from "react";
import StarRating from 'components/tutor/StarRating'

const TutorCard = ({ 
  id,                // Tutor's unique identifier 
  firstName,         // Tutor's first name
  lastName,          // Tutor's last name
  email,            // Tutor's email address
  subjects,         // Array of subjects taught by tutor
  avatar,           // URL to tutor's profile picture
  description,      // Tutor's bio/description
  isActive,         // Current active status
  tutor,            // Complete tutor object
  rating            // Tutor's rating
}: TutorCardProp) => {
  // Get current user data from global context
  const { currentUserData } = useAppContext();
  
  // Convert subjects prop to typed array
  const subjectsArrayOfObjects: Subject[] = subjects; 

  // Initialize navigation hook for routing
  const navigate = useNavigate();
  
  // State for managing selected subject
  const [selectedSubject, setSelectedSubject] = useState<Subject>({ name: '', price: '' });

  /**
   * Generates appropriate profile link based on user type
   * Returns my-profile for own profile, otherwise returns booking view
   * @returns URL string for profile navigation
   */
  const generateLink = () => {
    if (currentUserData?.email === email) return `/my-profile`;
    return `/book-a-tutor/show/${id}`;
  };

  /**
   * Truncates text to specified word limit
   * @param text - Text to truncate
   * @param maxWords - Maximum number of words to display
   * @returns Truncated text with ellipsis if needed
   */
  const truncateText = (text: string, maxWords: number) => {
    const words = text.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return text;
  };

  /**
   * Handles booking initiation
   * Navigates to booking page with necessary state
   * @param t - Tutor object
   * @param isImmediate - Whether this is an immediate booking
   */
  const handleClick = (t: any, isImmediate: boolean) => {
    const state = { tutorToBeBooked: t, isImmediate, selectedSubject };
    navigate(`/booking/create/${t._id}`, { state });
  };

  /**
   * Handles subject selection from dropdown
   * @param event - Select element change event
   */
  const handleSubjectSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedSubject = event.target.value;
    // Find and set complete subject object including price
    const foundSubject = tutor.subjects.find((subject) => subject.name === newSelectedSubject);
    if (foundSubject) {
      setSelectedSubject(foundSubject);
    }
  };

  return (
    <div className="flex items-start p-4 hover:shadow-md transition-all duration-300 w-full border border-gray-300 rounded-md bg-gray-200">
      {/* Profile Image and Status Section */}
      <div className="flex flex-col items-center">
        {/* Profile Image */}
        <img 
          src={avatar} 
          alt="Profile Picture" 
          className="rounded-md object-cover w-24 h-24" 
        />
        
        {/* Active Status Indicator */}
        <span className={`mt-2 ${isActive ? 'text-green-500' : 'text-red-500'}`}>
          {isActive ? (
            <>
              Active
              &#10004;
            </>
          ) : (
            <>
              Inactive
              &#10006;
            </>
          )}
        </span>
      </div>

      {/* Tutor Information Section */}
      <div className="flex flex-col justify-between mr-4 ml-4 w-3/5">
        {/* Name and Title */}
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-blue-900">
            <Link to={generateLink()}>{firstName} {lastName}</Link>
          </h2>
        </div>
  
        {/* Description */}
        <p className="text-sm text-gray-600 min-h-[40px] max-w-[75%]">
          {truncateText(description, window.innerWidth < 768 ? 12 : 35)}
        </p>

        {/* Rating Display */}
        <div className="flex items-center mt-3">
          <StarRating rating={rating} numberOfReviews={4} />
        </div>
      </div>
  
      {/* Booking Section */}
      <div className="flex flex-col">
        {/* Subject Selection Dropdown */}
        <label 
          htmlFor={`subjectSelect-${id}`} 
          className="text-sm text-gray-600 mb-1"
        >
          Select Subject:
        </label>
        <select 
          id={`subjectSelect-${id}`} 
          value={selectedSubject.name}  
          className="px-2 py-1 border border-gray-300 rounded-md focus:outline-none mb-4" 
          onChange={handleSubjectSelect}
        >
          <option value="" style={{ fontSize: '1rem', height: '1.5rem' }}></option>
          {subjectsArrayOfObjects.map((subject, index) => (
            <option key={index} value={subject.name}>
              {subject.name} - ${subject.price}/hr
            </option>
          ))}
        </select>

        {/* Booking Action Buttons */}
        <div className="flex flex-col items-center md:flex-row md:justify-center md:gap-4 space-y-2">
          {/* Schedule Button */}
          <Button
            variant="default"
            size="default"
            className="bg-primary text-primary-foreground hover:bg-primary/90"
            onClick={() => handleClick(tutor, false)}
            disabled={false}
          >
            Schedule Tutor
          </Button>

          {/* Instant Booking Button */}
          <Button
            variant="default"
            size="default"
            className={tutor.isActive ? 'bg-primary text-primary-foreground hover:bg-primary/90' : 'bg-ccc text-666'}
            onClick={() => tutor.isActive && handleClick(tutor, true)}
            disabled={!tutor.isActive}
          >
            Instant Booking
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TutorCard;