import { useAppContext } from "contexts/AppContext";
import { CredentialResponse } from "interfaces/google";
import React, { useState, useContext, useEffect, useRef} from "react";
import { useRouterContext, useLogin, useLogout, useTranslate } from "@refinedev/core";

function SignIn() {
  // State for managing username and password inputs
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Login mutation hook from Refine with Google authentication compatibility
  const { mutate: login } = useLogin<CredentialResponse>({
    v3LegacyAuthProviderCompatible: true
  });

  // Extract context values for managing login state
  const { loggedIn, setLoggedIn, currentUserData, currentTutorData } = useAppContext();

  // Component for rendering Google Sign-In button
  const GoogleButton: React.FC<{}> = ({}) => {
    // Reference to the div where Google button will be rendered
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      // Check if window, Google API, and div reference are available
      if (typeof window === "undefined" || !window.google || !divRef.current) {
        return;
      }
      
      try {
        // Initialize Google Sign-In with client configuration
        window.google.accounts.id.initialize({
          ux_mode: "popup", // Display sign-in as a popup
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!, // Google OAuth client ID
          callback: async (res: CredentialResponse) => {
            // Callback after successful Google Sign-In
            if (res.credential) {
              login(res); // Perform login using Refine's login hook
              setLoggedIn(true); // Update login state in context
            }
          },
        });

        // Render Google Sign-In button with specified theme and size
        window.google.accounts.id.renderButton(divRef.current, {
          theme: "filled_blue",
          size: "medium",
          type: "standard",
        });
      } catch (error) {
        console.log(error);
      }
    }, []); // Empty dependency array means this runs only once on component mount

    return <div ref={divRef} />; // Div where Google button will be rendered
  };

  // Placeholder method for traditional sign-in
  const handleSignIn = () => {
    // Add your sign-in logic here
  };

  // Placeholder method for Google Sign-In (not used, handled by GoogleButton)
  const handleGoogleSignIn = () => {};

  return (
    // Responsive container with centered sign-in form
    <div className="p-4 bg-gray-200 min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded-md shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">Sign In</h2>
        
        {/* Username input field */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md"
          />
        </div>

        {/* Password input field */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 p-2 w-full border rounded-md"
          />
        </div>

        {/* Traditional sign-in button */}
        <button
          onClick={handleSignIn}
          className="bg-blue-500 text-white p-2 rounded-md w-full hover:bg-blue-600"
        >
          Sign In
        </button>

        {/* Separator between traditional and Google sign-in */}
        <div className="mt-4 flex items-center justify-center">
          <div className="border-t border-gray-400 flex-grow"></div>
          <span className="mx-2 text-gray-500">Or</span>
          <div className="border-t border-gray-400 flex-grow"></div>
        </div>

        {/* Google Sign-In button component */}
        <GoogleButton />
      </div>
    </div>
  );
}

export default SignIn;