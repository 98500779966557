// Main App component using Refine framework for building a tutoring platform web application
// Handles routing, authentication, and user state management
import React, { useState, useEffect } from "react";
import { Refine, LegacyAuthProvider as AuthProvider } from "@refinedev/core";
import { notificationProvider, RefineSnackbarProvider, ReadyPage, ErrorComponent } from "@refinedev/mui";
import { CssBaseline, GlobalStyles } from "@mui/material";
import dataProvider from "@refinedev/simple-rest";
import routerProvider from "@refinedev/react-router-v6/legacy";
import axios, { InternalAxiosRequestConfig } from 'axios';
import { ColorModeContextProvider } from "contexts";
import { Title, Layout, Header, Footer } from "components/layout";
import { CredentialResponse } from "interfaces/google";
import { parseJwt } from "utils/parse-jwt";
import { TutorDashboard, BookATutor, Faq, OurApproach, TutorApplication, MyProfile, TutorProfile, Booking, Homepage, SignIn, AdminDashboard} from "pages";
import Checkout from "components/common/Checkout";
import { Success, Error } from "components";
import {
  AccountCircleOutlined,
  Person,
  LiveHelp,
  HistoryEdu,
  Info,
} from '@mui/icons-material'
import { useAppContext } from './contexts/AppContext';
import StudentDashboard from "pages/student-dashboard";
import 'css/tailwind.css'

// Create axios instance with custom interceptor to add authorization token to all requests
const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  // Retrieve JWT token from local storage
  const token = localStorage.getItem("token");
  if (request.headers) {
    // Add token to Authorization header
    request.headers["Authorization"] = `Bearer ${token}`;
  } else {
    request.headers = {
      Authorization: `Bearer ${token}`,
    } as any;  // TypeScript type workaround
  }
  return request;
});

function App() {
  console.log("first");
  // Initialize user variable to store user data
  let user : any | null = null;
  
  // Destructure context values from AppContext
  const { currentStudentData, currentTutorData, currentUserData, loggedIn, isMobile, setCurrentStudentData, setCurrentTutorData, setCurrentUserData, setLoggedIn, setIsMobile} = useAppContext();
  
  // State hooks for managing user type and visitor status
  const [isVisitor, setIsVisitor] = useState(true)
  const [isTutor, setIsTutor] = useState(Boolean);

  // Retrieve user data from local storage
  const localStorage_user = localStorage.getItem('user');
  if (localStorage_user !== null) {
    user = JSON.parse(localStorage_user.toString());
  }
  
  // Use effect hook to fetch user data on component mount or when user data changes
  useEffect(() => {
    console.log("inside effect STUDENT", currentStudentData)
    console.log("inside effect Tutor", currentTutorData)
    const fetchData = async () => {
      // Check if user exists and is not already logged in or has no tutor/student data
      if (user != null && !loggedIn && !currentTutorData && !currentStudentData) {
        const userId = user.userid;
        try {
          // Attempt to determine user type (student or tutor)
          await checkIfStudentOrTutor(userId);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
  
    fetchData();
  }, [currentTutorData, currentStudentData]);

  // Dynamically set dashboard based on user type
  const DashboardPage = loggedIn ? (isTutor ? TutorDashboard : StudentDashboard) : Homepage;

  console.log("running in app.tsx, user is " , user);

  // Authentication provider configuration for Refine
  const authProvider: AuthProvider = {   
    // Login method handling Google OAuth authentication
    login: async ({ credential }: CredentialResponse) => {
      // Parse JWT token to get user profile
      const profileObj = credential ? parseJwt(credential) : null;
      if (profileObj) {
        // Create or retrieve user in the backend
        const createuser = await fetch(`${process.env.REACT_APP_SERVER}/api/v1/users`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            name: profileObj.name,
            firstName: profileObj.name.substring(0,profileObj.name.lastIndexOf(" ")),
            lastName: profileObj.name.substring(profileObj.name.lastIndexOf(" ")+1,profileObj.name.length),                 
            email: profileObj.email,
            avatar: profileObj.picture
          })
        })

        const user = await createuser.json();
      
        // Store user data in local storage if user creation is successful
        if(createuser.status === 200 || createuser.status === 201) {
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...profileObj,
              avatar: profileObj.picture,
              userid: user._id
            })
          );
        } else {
          return Promise.reject()
        }

        // Check if user is a tutor or student
        await checkIfStudentOrTutor(user._id);
      }
      
      // Store authentication token
      localStorage.setItem("token", `${credential}`);

      return Promise.resolve();
    },
    
    // Logout method to clear user session and revoke Google token
    logout: () => {
      const token = localStorage.getItem("token");

      if (token && typeof window !== "undefined") {
        // Remove local storage items
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        axios.defaults.headers.common = {};
        // Revoke Google OAuth token
        window.google?.accounts.id.revoke(token, () => {
          return Promise.resolve("/home");
        });
      }

      // Reset application state
      setLoggedIn(false);
      setCurrentStudentData(null)
      setCurrentTutorData(null)
      
      return Promise.resolve("/home");
    },
    
    // Basic error checking method
    checkError: () => Promise.resolve(),
    
    // Authentication check method (always resolves)
    checkAuth: async () => {
      return Promise.resolve();
    },

    // Permissions and user identity methods
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
      const user = localStorage.getItem("user");
      if (user) {
        return Promise.resolve(JSON.parse(user));
      }
    },
  };

  // Check if a user is a tutor
  async function checkIfTutor(userId : any) {
    const tutorCheck = await fetch(`${process.env.REACT_APP_SERVER}/api/v1/tutors/${userId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    const tutor = await tutorCheck.json();

    if (tutorCheck.status === 200){
      // Set tutor flag and store tutor data
      setIsTutor(true)
      
      const parsedTutorData: any = {
        ...tutor,
        subjects: tutor.subjects ? JSON.parse(tutor.subjects) : [],
        schedule: tutor.schedule ? JSON.parse(tutor.schedule) : [],
      };
      setCurrentTutorData(parsedTutorData)
      return true;
    } 
    else if (tutorCheck.status === 404){
      // User is not a tutor
      setIsTutor(false)
      return false
    }
  }

  // Retrieve or create student data
  async function getStudentData(userId: any) {
    try {
      const createStudentRequest = await fetch(`${process.env.REACT_APP_SERVER}/api/v1/students`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: userId
        })
      });
  
      const student = await createStudentRequest.json();
      // Store student data in context
      setCurrentStudentData(student);
      return true;
    } catch (error) {
      console.error("Error fetching student data:", error);
      return false;
    }
  }

  // Determine user type (student or tutor)
  async function checkIfStudentOrTutor(userId : any) {
    // Fetch user details
    const userCheck = await fetch(`${process.env.REACT_APP_SERVER}/api/v1/users/${userId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    const user = await userCheck.json();
    // Update user data and login status
    setCurrentUserData(user);
    setLoggedIn(true)

    // Check if user is a tutor or student
    if (await checkIfTutor(userId)) {
      console.log("user is a tutor")
    }
    else if(await getStudentData(userId)){
      console.log("user is a student")
    }
  }

  // Render application with Refine configuration
  return (
    <>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <Refine
          dataProvider={dataProvider(`${process.env.REACT_APP_SERVER}/api/v1`)}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          // Define application resources (routes and pages)
          resources={[
            {
              name: "admin-dashboard",
              list: AdminDashboard,
            }, 
            {
              name: "sign-in",
              list: SignIn,
            },
            {
              name: "book-a-tutor",
              meta: {label: 'Book A Tutor', hide: true },
              list: BookATutor,
              show: TutorProfile,
              icon: <Person />,
            },
            {
              name: "faq",
              meta: {label: 'FAQ' },
              list: Faq,
              icon: <LiveHelp />,
            },
            {
              name: "our-approach",
              list: OurApproach,
              icon: <Info />,
            },
            {
              name: "tutor-application",
              list: TutorApplication,
              icon: <HistoryEdu />,
              meta: {hide: true }
            },
            {
              name: "my-profile",
              meta: {label: 'My Profile', hide: true },
              list: MyProfile,
              icon: <AccountCircleOutlined />
            },
            {
              name: "booking",
              create: Booking,
              meta: {hide: true },
            },        
            { 
              name: "home", 
              list: Homepage 
            },       
            {
              name: "checkout",
              list: Checkout,
              meta: {hide: true },
            },
            {
              name: "success",
              list: Success,
              meta: {hide: true },
            },{
              name: "error",
              list: Error,
              meta: {hide: true },
            },
          ]}
          // Configuration for layout and routing
          Title={Title}
          Layout={Layout}
          Header={Header}
          Footer={Footer} 
          legacyRouterProvider={routerProvider}
          legacyAuthProvider={authProvider}
          // Dynamically set dashboard based on user type
          DashboardPage={DashboardPage}
        >
        </Refine>
      </RefineSnackbarProvider>
    </>
  )
}

export default App;