/**
 * MobileNav Component
 * Provides responsive navigation menu for mobile devices
 * Implements a slide-out sheet with navigation options
 */
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "../../ui/sheet"
import { Separator } from "../../ui/separator"
import NavItems from "./navItems"

const MobileNav = () => {
  return (
    // Mobile navigation container - only visible on mobile devices
    <nav className="md:hidden">
      {/* Sheet component for slide-out menu */}
      <Sheet>
        {/* Menu trigger button with hamburger icon */}
        <SheetTrigger className="align-middle">
          <img
            src="./assets/icons/menu.svg"
            alt="menu"
            width={24}
            height={24}
            className="cursor-pointer"
          />
        </SheetTrigger>

        {/* Slide-out menu content */}
        <SheetContent className="flex flex-col gap-6 bg-black md:hidden">
          {/* Logo in mobile menu */}
          <img 
            src="/assets/images/sc_logo.png"
            alt="logo"
            width={128}
            height={38}
          />

          {/* Visual separator between logo and navigation items */}
          <Separator className="border border-gray-50" />

          {/* Navigation items - reused from NavItems component */}
          <NavItems />
        </SheetContent>
      </Sheet>
    </nav>
  )
}

export default MobileNav