import React, { useState, useEffect } from "react";
//import "css/checkout.css"

const Message = ({ message } : any) => (
    <section>
      <p>{message}</p>
    </section>
  );

const ProductDisplay = () => (
    <section>
      <h2 className="check">Checkout</h2>
      <div className="description">
        <div className="product">
          <img src="https://res.cloudinary.com/ugwutotheeshoes/image/upload/v1661613925/binance_logo.png"
            alt="The cover of Binance USD"
          />
          <h2>Binance USD</h2>
        </div>
        <p>US$50.00</p>
      </div>
      <form action="http://localhost:8080/api/v1/create-checkout-session" method="POST">
        <div className="btn">
          <button className="checkout" type="submit">
            Checkout
          </button>
        </div>
      </form>
    </section>
   );

export default function Checkout() {
    const [message, setMessage] = useState("");
    useEffect(() => {
      // Check to see if this is a redirect back from Checkout
      const query = new URLSearchParams(window.location.search);
      if (query.get("success")) {
        setMessage("Order placed! You will receive an email confirmation.");
        console.log("yaay");
      }
      if (query.get("canceled")) {
        setMessage(
          "Order canceled -- continue to shop around and checkout when you're ready."
        );
        console.log("nahh");
      }
   }, []);
    return message ? <Message message={message} /> : <ProductDisplay />;
  }