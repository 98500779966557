import React, { useState, useEffect } from 'react';
import { useList } from "@refinedev/core";
import { Typography } from "@mui/material";
import { Booking } from 'interfaces/common';
import { format, parse, add, isBefore, isAfter, isWithinInterval } from 'date-fns';
import { useAppContext } from 'contexts/AppContext';
import { MeetingCard } from 'components';

const Sessions = () => {
  const { currentStudentData, currentTutorData } = useAppContext();
  const { data, isLoading, isError } = useList({ resource: 'booking' });

  if (isLoading) return <Typography>Loading...</Typography>;
  if (isError) return <Typography>Error...</Typography>;

  const currentDateTime = new Date();

  const upcomingBookings: Booking[] = [];
  const pastBookings: Booking[] = [];

  const userBookings = (currentStudentData != null)
    ? data?.data.filter((booking) => booking.StudentId === currentStudentData._id)
    : data?.data.filter((booking) => booking.TutorId === currentTutorData._id);

  const bookings: Booking[] = (userBookings).map((booking) => {
    const bookingDate = parse(`${booking.date} ${booking.startTime}`, 'yyyy-MM-dd HH:mm:ss', new Date());
    const zoomMeeting = JSON.parse(booking.zoomMeeting);
    const bookingEndTime = add(bookingDate, { minutes: zoomMeeting.duration });

    const parsedBooking = {
      _id: booking._id,
      date: booking.date,
      startTime: booking.startTime,
      zoomMeeting: zoomMeeting,
    };

    if (isBefore(bookingEndTime, currentDateTime)) {
      pastBookings.push({
        ...parsedBooking,
        endTime: format(bookingEndTime, 'HH:mm:ss'),
      });
    } else if (isAfter(bookingDate, currentDateTime) || isWithinInterval(currentDateTime, { start: bookingDate, end: bookingEndTime })) {
      upcomingBookings.push(parsedBooking);
    }

    return parsedBooking;
  });

  console.log('Upcoming Bookings:', upcomingBookings);
  console.log(upcomingBookings[0]?.zoomMeeting.join_url)
  console.log(upcomingBookings[0]?.zoomMeeting.start_url)
  console.log('Past Bookings:', pastBookings);

  return (
    <div className="text-center">
      <h2 className="text-3xl font-bold mb-8">Your Upcoming Zoom Meetings</h2>
      <ul>
        {upcomingBookings.map((booking) => (
          <MeetingCard
            key={booking._id}
            id={booking._id}
            date={booking.date}
            startTime={booking.startTime}
            start_url={booking.zoomMeeting.start_url}
            join_url={booking.zoomMeeting.join_url}
            duration={booking.zoomMeeting.duration}
            topic={booking.zoomMeeting.topic}
          />
        ))}
      </ul>
      <h2 className="text-3xl font-bold my-8">Your Past Zoom Meetings</h2>
      <ul>
        {pastBookings.map((booking) => (
          <MeetingCard
            key={booking._id}
            id={booking._id}
            date={booking.date}
            startTime={booking.startTime}
            start_url={booking.zoomMeeting.start_url}
            join_url={booking.zoomMeeting.join_url}
            duration={booking.zoomMeeting.duration}
            topic={booking.zoomMeeting.topic}
          />
        ))}
      </ul>
    </div>
  );
};

export default Sessions;
