/**
 * StarRating Component
 * Displays a visual rating using filled and empty stars
 * Shows number of reviews alongside the star rating
 */
import React from 'react';

// Define the expected props for the component
interface StarRatingProps {
    rating: number;           // Numerical rating value
    numberOfReviews: number;  // Total number of reviews
}

const StarRating: React.FC<StarRatingProps> = ({ rating, numberOfReviews }) => {
    // Round the rating to the nearest whole number for star display
    const filledStars = Math.round(rating);
    
    // Calculate remaining empty stars needed
    const emptyStars = 5 - filledStars;

    return (
        // Container for star rating display
        <div className="flex items-center">
            {/* Render filled stars */}
            {[...Array(filledStars)].map((_, index) => (
                <img 
                    key={index} 
                    src="/assets/icons/filled-star.png" 
                    alt="filled star" 
                    className="h-5 w-5" 
                />
            ))}

            {/* Render empty stars */}
            {[...Array(emptyStars)].map((_, index) => (
                <img 
                    key={index} 
                    src="/assets/icons/empty-star.png" 
                    alt="empty star" 
                    className="h-5 w-5" 
                />
            ))}

            {/* Display review count */}
            <span className="ml-1 text-sm text-gray-600">
                ({numberOfReviews})
            </span>
        </div>
    );
};

export default StarRating;