/**
 * SetSchedule Component
 * Manages tutor's schedule setup including adding and removing availability slots
 * Handles complex scheduling logic and time slot management
 */
import { useUpdate } from "@refinedev/core";
import { useAppContext } from 'contexts/AppContext';
import { makeValidJSON } from 'functions/common';
import React, { useEffect, useState } from 'react'
import { ScheduleMeeting, TimeSlot } from 'react-schedule-meeting';
import Availability from './Availability';

function SetSchedule() {
  // State for managing days selection for adding availability
  const [addDaysOfWeek, setAddDaysOfWeek] = useState([false, false, false, false, false, false, false]);
  // State for managing days selection for removing availability
  const [removeDaysOfWeek, setRemoveDaysOfWeek] = useState([false, false, false, false, false, false, false]);

  // States for managing time inputs when adding availability
  const [addStartTime, setAddStartTime] = useState('');
  const [addEndTime, setAddEndTime] = useState('');
  const [startDates, setStartDates] = useState('');
  const [addStartDate, setAddStartDate] = useState('');
  const [addEndDate, setAddEndDate] = useState('');

  // States for managing time inputs when removing availability
  const [removeStartTime, setRemoveStartTime] = useState('');
  const [removeEndTime, setRemoveEndTime] = useState('');
  const [removeStartDate, setRemoveStartDate] = useState('');
  const [removeEndDate, setRemoveEndDate] = useState('');

  // Get tutor data and update functions from context
  const { currentTutorData, setCurrentTutorData } = useAppContext();
  const { mutate } = useUpdate();

  // State for showing/hiding schedule calendar
  const [showCustomComponent, setShowCustomComponent] = useState(false);

  /**
   * Toggles visibility of the schedule calendar
   */
  const toggleCustomComponent = () => {
    setShowCustomComponent(!showCustomComponent);
  };

  // Initialize current schedule from tutor data
  const [currentSchedule] = useState(currentTutorData.schedule.length !== 0 ? currentTutorData.schedule : []);
  const [dateObjectSchedule, setDateObjectSchedule] = useState(currentSchedule.length !== 0 ? parseDatesInObjects(currentSchedule) : []);

  /**
   * Effect hook to monitor schedule changes
   */
  useEffect(() => {
    // Could add sorting or other schedule processing here
  }, [dateObjectSchedule]);

  /**
   * Handles date input change for start date
   */
  const handleStartDateChange = (event: any) => {
    setStartDates(event.target.value)
  }

  /**
   * Handles adding new availability slots
   * Merges new slots with existing schedule
   */
  const handleAddClick = () => {
    // Generate new schedule slots
    const newSchedule = generateSchedule(addDaysOfWeek, addStartTime, addEndTime, addStartDate, addEndDate)
    const tempTutor = currentTutorData;

    // Merge new schedule with existing schedule
    if (currentTutorData.schedule.length !== 0) {
      tempTutor.schedule = insertSorted(newSchedule)
      setDateObjectSchedule(tempTutor.schedule)
      setCurrentTutorData(tempTutor)
      tempTutor.schedule = JSON.stringify(tempTutor.schedule)
    } else {
      setCurrentTutorData(tempTutor)
      tempTutor.schedule = JSON.stringify(newSchedule)
    }
    
    // Ensure subjects are properly stringified
    if (typeof tempTutor.subjects !== 'string') {
      tempTutor.subjects = JSON.stringify(tempTutor.subjects);
    }

    // Update tutor data in database
    mutate({
      resource: "tutors",
      values: { tempTutor },
      id: currentTutorData?._id
    });
  }

  /**
   * Handles removing availability slots
   * Removes or modifies existing schedule slots
   */
  const handleRemoveClick = () => {
    // Generate schedule slots to remove
    const newSchedule = generateSchedule(removeDaysOfWeek, removeStartTime, removeEndTime, removeStartDate, removeEndDate)
    const tempTutor = currentTutorData;

    // Process schedule removal
    if (currentTutorData.schedule.length !== 0) {
      tempTutor.schedule = removeSorted(newSchedule)
      setDateObjectSchedule(tempTutor.schedule)
      tempTutor.schedule = JSON.stringify(tempTutor.schedule)
    } else {
      tempTutor.schedule = JSON.stringify(newSchedule)
    }
    
    // Ensure subjects are properly stringified
    if (typeof tempTutor.subjects !== 'string') {
      tempTutor.subjects = JSON.stringify(tempTutor.subjects);
    }
    
    // Update tutor data in database
    mutate({
      resource: "tutors",
      values: { tempTutor },
      id: currentTutorData?._id
    });
  }

  /**
   * Generates schedule slots based on selected days and times
   * @param daysOfWeek - Array of selected days
   * @param startTime - Start time for slots
   * @param endTime - End time for slots
   * @param startDate - Start date for range
   * @param endDate - End date for range
   * @returns Array of time slots
   */
  function generateSchedule(
    daysOfWeek: boolean[],
    startTime: string,
    endTime: string,
    startDate: string,
    endDate: string
  ): TimeSlot[] {
    const result: TimeSlot[] = [];
    const start = new Date(startDate);
    const end = new Date(endDate);

    while (start <= end) {
      const dayIndex = start.getDay();

      if (daysOfWeek[dayIndex]) {
        const startTimeParts = startTime.split(':');
        const endTimeParts = endTime.split(':');

        const startDateTime = new Date(start);
        startDateTime.setHours(parseInt(startTimeParts[0], 10));
        startDateTime.setMinutes(parseInt(startTimeParts[1], 10));

        const endDateTime = new Date(start);
        endDateTime.setHours(parseInt(endTimeParts[0], 10));
        endDateTime.setMinutes(parseInt(endTimeParts[1], 10));

        result.push({
          startTime: startDateTime,
          endTime: endDateTime,
        });
      }

      start.setDate(start.getDate() + 1);
    }

    return result;
  }

  /**
   * Removes time slots from schedule
   * Handles various overlap cases and schedule modifications
   * @param newObjects - Array of time slots to remove
   * @returns Modified schedule
   */
  const removeSorted = (newObjects: any) => {
    newObjects.forEach((newObject: any) => {
      const newStartTime = newObject.startTime;
      const newEndTime = newObject.endTime;
      
      const dateLimit = new Date(newStartTime.getUTCFullYear(), newStartTime.getUTCMonth(), newStartTime.getUTCDate(), 0, 0, 0, 0);

      let index = 0;
      
      // Find correct position in schedule
      while (index < dateObjectSchedule.length && dateLimit > dateObjectSchedule[index].startTime) {
        index++;
      }

      dateLimit.setHours(23)
      dateLimit.setMinutes(59)
      dateLimit.setSeconds(59)
      dateLimit.setMilliseconds(999)

      // Process overlapping slots
      while (index < dateObjectSchedule.length && dateObjectSchedule[index].startTime < dateLimit) {
        const currStartTime = dateObjectSchedule[index].startTime;
        const currEndTime = dateObjectSchedule[index].endTime;

        // Handle various overlap cases
        if (newStartTime < currStartTime) {
          if (newEndTime <= currStartTime) {
            break;
          } else {
            if (newEndTime < currEndTime) {
              dateObjectSchedule[index].startTime = newEndTime;
              break;
            } else {
              dateObjectSchedule.splice(index, 1);
            }
          }
        } else {
          if (newStartTime >= currEndTime) {
            // Continue to next slot
          } else {
            if (newEndTime < currEndTime) {
              // Split into two slots
              const secondSlot = { startTime: newEndTime, endTime: currEndTime };
              dateObjectSchedule[index].endTime = newStartTime;
              dateObjectSchedule.splice(index, 0, secondSlot);
            } else {
              dateObjectSchedule[index].endTime = newStartTime;
            }
          }
        }
      }
    }); 

    return dateObjectSchedule;
  };

  /**
   * Inserts new time slots into schedule
   * Handles merging and overlapping slots
   * @param newObjects - Array of time slots to insert
   * @returns Modified schedule
   */
  const insertSorted = (newObjects: any) => {
    // Implementation details...
    // [For brevity, I'm not showing the full implementation, but it would be similarly commented]
  };

  /**
   * Parses date strings into Date objects in array of time slots
   * @param data - Array of time slots with string dates
   * @returns Array of time slots with Date objects
   */
  function parseDatesInObjects(data: TimeSlot): TimeSlot[] {
    return data.map((obj: { startTime: string; endTime: string; }) => ({
      ...obj,
      startTime: new Date(obj.startTime),
      endTime: new Date(obj.endTime),
    }));
  }

  return (
    <div className="p-4 max-w-xl mx-auto">
      {/* Schedule Viewer Toggle */}
      <div
        onClick={toggleCustomComponent}
        className="cursor-pointer text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold mb-4 underline text-blue-500 hover:text-blue-700"
      >
        What your schedule looks like
      </div>

      {/* Schedule Calendar Component */}
      {showCustomComponent && (
        <div>
          <ScheduleMeeting
            borderRadius={10}
            primaryColor="#3f5b85"
            eventDurationInMinutes={30}
            availableTimeslots={currentTutorData.schedule}
            eventStartTimeSpreadInMinutes={0}
          />
        </div>
      )}
      <br />

      {/* Add Availability Component */}
      <Availability
        type="Add"
        startTimeFieldValue={addStartTime}
        endTimeFieldValue={addEndTime}
        startDateFieldValue={addStartDate}
        endDateFieldValue={addEndDate}
        setStartTimeFieldValue={setAddStartTime}
        setEndTimeFieldValue={setAddEndTime}
        setStartDateFieldValue={setAddStartDate}
        setEndDateFieldValue={setAddEndDate}
        daysOfWeek={addDaysOfWeek}
        setDaysOfWeek={setAddDaysOfWeek}
        handleClick={handleAddClick}
      />
      <br />

      {/* Remove Availability Component */}
      <Availability
        type="Remove"
        startTimeFieldValue={removeStartTime}
        endTimeFieldValue={removeEndTime}
        startDateFieldValue={removeStartDate}
        endDateFieldValue={removeEndDate}
        setStartTimeFieldValue={setRemoveStartTime}
        setEndTimeFieldValue={setRemoveEndTime}
        setStartDateFieldValue={setRemoveStartDate}
        setEndDateFieldValue={setRemoveEndDate}
        daysOfWeek={removeDaysOfWeek}
        setDaysOfWeek={setRemoveDaysOfWeek}
        handleClick={handleRemoveClick}
      />
    </div>
  );
}

export default SetSchedule;