import React, { useState } from 'react';
import { MeetingCardProp } from 'interfaces/tutor';
import { Button } from 'components/ui/button';
import { useAppContext } from 'contexts/AppContext';

const MeetingCard = ({ id, date, startTime, start_url, join_url, duration, topic } : MeetingCardProp) => {

  const { currentStudentData } = useAppContext();
  const [isTutor, setIsTutor] = useState(currentStudentData === null);

  const startZoomMeeting = () => {
    window.open(start_url, '_blank');
  };

  const joinZoomMeeting = () => {
    window.open(join_url, '_blank');
  };

  return (
    <div className="mx-auto max-w-2xl bg-white rounded-md shadow-md overflow-hidden p-4 mb-4 transition-transform duration-300 transform hover:scale-105">
      <h2 className="text-xl font-semibold text-blue-900 mb-4">{topic}</h2>

      <div className="flex flex-col md:flex-row gap-4">
        <div className="w-full md:w-1/2 flex flex-col">
          <p className="text-sm text-gray-600 mb-2">Date: {date}</p>
          <p className="text-sm text-gray-600 mb-2">Start Time: {startTime}</p>
          <p className="text-sm text-gray-600 mb-2">Duration: {duration}</p>
        </div>

        <div className="w-full md:w-1/2 flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
          {isTutor ? <Button
          variant="default"
          size="default"
          className="bg-primary text-primary-foreground hover:bg-primary/90"
          onClick={() => startZoomMeeting()}
          disabled={false}
        >
          Start Meeting
        </Button> :  <Button
          variant="default"
          size="default"
          className="bg-primary text-primary-foreground hover:bg-primary/90"
          onClick={() => joinZoomMeeting()}
          disabled={false}
        >
          Join Meeting
        </Button>
        }
        </div>
      </div>
    </div>
  );
};

export default MeetingCard;
