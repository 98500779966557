import React, { useState } from 'react';
import { email, emailAd, Invoice } from 'assets';
import '../css/FAQ.css';
import { Typography } from "@mui/material";
import Accordion from './Accordion';

function FAQ() {
  const [tabIndex, setTabIndex] = useState(1);

  const studentFaq = [
    {
      question: 'How do students use the site?',
      answer: 'Students should make an account to see the list of available tutors. Once the account is made, students should head over to their profile and select "Edit Profile", and fill out their account information there. Scheduling bookings will use the information you put here. You can look at the bookings you\'ve made by clicking on "Sessions" which is under the dropdown, and you can join the Zoom link from here.',
    },
    {
      question: 'Finding the right tutor',
      answer: 'Under the "Book a Tutor" tab, you can search for tutors by subject. You can view each tutor\'s profile and see their qualifications and biography. If you want a tutor as soon as possible, look for tutors who have the "Immediate Tutoring" option available.',
    },
    {
      question: 'How to pay for the session',
      answer: (
        <div>
          <p>
            After the session, you will receive an email from this sender:
          </p>
          <div className="flex flex-col sm:flex-row items-center">
            <img src={emailAd} alt="Email Address" className="mb-2 sm:mb-0 mr-2" />
            <img src={email} alt="Email" className="mb-2 sm:mb-0 mr-2" />
            <img src={Invoice} alt="Invoice" className="mb-2 sm:mb-0 mr-2" />
          </div>
          <Typography variant="body2" className="mt-2">
            Pay the invoice
          </Typography>
        </div>
      ),
    },
  ];

  const tutorFaq = [
    {
      question: 'How do Tutors use the site?',
      answer: 'Tutors should make sure their Account information on their profile is filled out. Once that is complete, look out for emails notifying you that bookings have been made for you. You can manage them under "Manage my Bookings", and this will be where you charge your customers based on session length. You can also go to "My Calendar" to have a calendar view of bookings, get the zoom link, and mark students\' attendance.',
    },
    {
      question: 'Becoming a Tutor',
      answer: 'In order to submit a Tutor Application, you must have an account first. Currently, the Tutor Application page is under the "More" tab at the top. Once submitted, the application will go under review, and you will be notified of any updates. If accepted, you will be invited to have Calendar access on our website, allowing you to view / manage bookings made for you in a neat way.',
    },
    {
      question: 'I don\'t appear under "Book A Tutor"',
      answer: 'Most likely the reason you are not appearing here is that your profile is private. Head over to "My Account" or edit your profile to change your profile visibility.',
    },
    {
      question: 'There are no subjects showing under my dropdown',
      answer: 'Make sure to head to your profile and select the subjects at the bottom of the page. You also need to select at least one grade range.',
    },
  ];

  return (
    <div className="p-4 bg-gray-200 container mx-auto px-4 max-w-screen-xl">
      <main className="text-center">
        <Typography variant="h3">Frequently Asked Questions</Typography>

        <div className="flex justify-center space-x-4 mt-4">
          <Typography
            variant="h6"
            className={`cursor-pointer ${
              tabIndex === 1 ? 'text-blue-600 font-bold' : 'text-gray-600'
            }`}
            onClick={() => setTabIndex(1)}
          >
            Student
          </Typography>
          <Typography
            variant="h6"
            className={`cursor-pointer ${
              tabIndex === 2 ? 'text-blue-600 font-bold' : 'text-gray-600'
            }`}
            onClick={() => setTabIndex(2)}
          >
            Tutor
          </Typography>
        </div>
      </main>

      <div className="mt-8 space-y-4">
        {(tabIndex === 1
          ? studentFaq
          : tutorFaq
        ).map((item, i) => (
          <Accordion
            key={i}
            title={item.question}
            answer={item.answer}
          />
        ))}
      </div>
    </div>
  );
}

export default FAQ;
