import { FormProps } from 'interfaces/common';
import CustomButton from './CustomButton';
import { useList } from "@refinedev/core";
import { useState } from 'react';

const Form = ({ type, register, handleSubmit, handleResumeChange, formLoading, onFinishHandler, resume }: FormProps) => {

  const { data: subjectsData } = useList({
    resource: 'subjects',
  });

  const [selectedSubjects, setSelectedSubjects] = useState<{ [key: number]: boolean }>({});

  const subjects = subjectsData?.data ?? [];

  console.log("Subjects are: ", subjects)
  return (
    <div className='container mx-auto px-4 max-w-screen-xl'>
      <h1 className="text-3xl font-bold mb-4">{type} a Tutor Application</h1>
      <div className="bg-gray-100 p-6 rounded-lg">
        <form onSubmit={handleSubmit(onFinishHandler)} className="flex flex-col gap-6">
          <div className="flex flex-col md:flex-row gap-4">
            <label className="text-base font-semibold">Phone Number</label>
            <input
              type="text"
              className="border border-gray-300 rounded-md px-4 py-2"
              {...register('number', { required: true })}
            />
          </div>

          <div>
            <label className="text-base font-semibold">Describe Yourself</label>
            <textarea
              rows={5}
              placeholder="Write Description"
              className="w-full border border-gray-300 rounded-md px-4 py-2"
              {...register('description', { required: true })}
            />
          </div>

          <div className="flex flex-col md:flex-row gap-10">
            <div className="flex-1">
              <label className="text-base font-semibold">Degree</label>
              <select
                className="w-full border border-gray-300 rounded-md px-4 py-2"
                {...register('degree', { required: true })}
              >
                <option value="mathematics">Mathematics</option>
                <option value="chemistry">Chemistry</option>
                <option value="english">English</option>
                <option value="history">History</option>
                <option value="computing">Computing</option>
              </select>
            </div>

            <div className="flex-1">
              <label className="text-base font-semibold">Subjects</label>
              <div className="flex flex-wrap gap-4">
              {subjects.map((subject, index) => (
                <div key={index} className="flex items-center">
                  <input
                    type="checkbox"
                    {...register(`subjects[${index}].selected`)}
                    onChange={() => {
                      setSelectedSubjects((prevSelectedSubjects) => {
    
                        const newSelectedSubjects = { ...prevSelectedSubjects };
                        
                        newSelectedSubjects[index] = !newSelectedSubjects[index];

                        return newSelectedSubjects;
                      });
                    }}
                  />
                  <label className="ml-2">{subject.name}</label>
                  <input
                    type="number"
                    placeholder="Enter price"
                    {...register(`subjects[${index}].price`, {
                      required: selectedSubjects[index], 
                      min: 0,
                    })}
                    className="ml-2 border border-gray-300 rounded-md px-4 py-2"
                  />
                  <input
                    type="hidden"
                    value={subject.name}
                    {...register(`subjects[${index}].name`)}
                  />
                </div>
              ))}
              </div>
            </div>
          </div>

          <div>
            <label className="text-base font-semibold">Country</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-md px-4 py-2"
              {...register('country', { required: true })}
            />
          </div>

          <div className="flex flex-col gap-2 justify-center mb-2">
            <div className="flex gap-2">
              <p className="text-base font-semibold">Upload Resume (.pdf, .doc, .docx)</p>
              <label
                htmlFor="resume-upload"
                className="border border-blue-500 px-4 py-2 rounded-md cursor-pointer"
              >
                Upload *
              </label>
              <input
                id="resume-upload"
                hidden
                type="file"
                //@ts-ignore
                onChange={(e) => handleResumeChange(e.target.files[0])}
              />
            </div>
            <p className="text-sm text-gray-600">{resume?.name}</p>
            <CustomButton
              type="submit"
              title={formLoading ? 'Submitting...' : 'Submit'}
              backgroundColor="#475be8"
              color="fcfcfc"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
