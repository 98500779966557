/**
 * Availability Component
 * Manages tutor availability settings including time slots and recurring schedules
 * Provides interface for setting up weekly schedules and time ranges
 */
import { Button } from '../ui/button'

interface AvailabilityProps {
  type: string,                           // Type of availability setting (Add/Remove)
  startTimeFieldValue: string,            // Selected start time
  endTimeFieldValue: string,              // Selected end time
  startDateFieldValue: string,            // Selected start date
  endDateFieldValue: string,              // Selected end date
  setStartTimeFieldValue: Function,       // Start time setter
  setEndTimeFieldValue: Function,         // End time setter
  setStartDateFieldValue: Function,       // Start date setter
  setEndDateFieldValue: Function,         // End date setter
  daysOfWeek: boolean[],                  // Array of selected days
  setDaysOfWeek: Function,                // Days selection setter
  handleClick: Function                   // Click handler for submission
}

function Availability({
  type, 
  startTimeFieldValue, 
  endTimeFieldValue, 
  startDateFieldValue, 
  endDateFieldValue, 
  setStartTimeFieldValue, 
  setEndTimeFieldValue, 
  setStartDateFieldValue, 
  setEndDateFieldValue, 
  daysOfWeek, 
  setDaysOfWeek, 
  handleClick 
}: any) {
    // Constants for day names in order (Sunday to Saturday)
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
    /**
     * Handles changes to day selection checkboxes
     * Updates the daysOfWeek array when a day is selected/deselected
     * @param index - Index of the day being toggled
     */
    const handleDayOfWeekChange = (index: any) => {
        const updatedDaysOfWeek = [...daysOfWeek];
        updatedDaysOfWeek[index] = !updatedDaysOfWeek[index];
        setDaysOfWeek(updatedDaysOfWeek);
        console.log(updatedDaysOfWeek)
    };
  
    /**
     * Event handlers for time and date field changes
     * Update respective state values when inputs change
     */
    const handleStartFieldChange = (event: any) => {setStartTimeFieldValue(event.target.value)}
    const handleEndFieldChange = (event: any) => {setEndTimeFieldValue(event.target.value)}
    const handleStartDateFieldChange = (event: any) => {setStartDateFieldValue(event.target.value)}
    const handleEndDateFieldChange = (event: any) => {setEndDateFieldValue(event.target.value)}

    return (
      <div className="p-4">
        {/* Section Header */}
        <div className="mb-4">
          <h1 className="font-bold text-xl md:text-2xl lg:text-3xl xl:text-4xl">
            {type} Availability:
          </h1>
        </div>
    
        {/* Time Selection Section */}
        <div className="flex gap-2">
          {/* Start Time Input */}
          <input
            type="time"
            className="border border-gray-300 rounded-md p-2 mb-2 text-sm md:text-base md:w-40 lg:w-48 xl:w-56"
            required
            value={startTimeFieldValue}
            onChange={handleStartFieldChange}
          />
    
          {/* End Time Input */}
          <input
            type="time"
            className="border border-gray-300 rounded-md p-2 mb-2 text-sm md:text-base md:w-40 lg:w-48 xl:w-56"
            required
            value={endTimeFieldValue}
            onChange={handleEndFieldChange}
          />
        </div>
    
        {/* Date Selection Section */}
        <div className="flex gap-2">
          {/* Start Date Input */}
          <input
            type="date"
            className="border border-gray-300 rounded-md p-2 mb-2 text-sm md:text-base md:w-40 lg:w-48 xl:w-56"
            required
            value={startDateFieldValue}
            onChange={handleStartDateFieldChange}
          />
    
          {/* End Date Input */}
          <input
            type="date"
            className="border border-gray-300 rounded-md p-2 mb-2 text-sm md:text-base md:w-40 lg:w-48 xl:w-56"
            required
            value={endDateFieldValue}
            onChange={handleEndDateFieldChange}
          />
        </div>
    
        {/* Days of Week Selection */}
        <div>
          <h2 className="text-xl font-bold mb-2">Select Days of the Week:</h2>
          <div className="flex gap-2">
            {/* Map through days and create checkboxes */}
            {dayNames.map((day, index) => (
              <div key={index} className="flex items-center">
                <input
                  type="checkbox"
                  checked={daysOfWeek[index]}
                  onChange={() => handleDayOfWeekChange(index)}
                  className="h-5 w-5 md:h-5 md:w-5 lg:h-6 lg:w-6 xl:h-7 xl:w-7"
                />
                <label className="ml-2 text-sm">{day.substring(0, 2)}</label>
              </div>
            ))}
          </div>
    
          {/* Submit Button */}
          <Button
            color="#fcfcfc"
            onClick={handleClick}
            className="mt-5 px-6 py-3 text-sm md:text-base lg:text-lg xl:text-xl h-12 w-36 md:w-48 lg:w-60 xl:w-72"
          >
            {type} Availability
          </Button>
        </div>
      </div>
    );
}

export default Availability;