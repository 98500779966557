import React, { useState } from "react";
import { useUpdate } from "@refinedev/core";
import { Profile } from "components";
import { useAppContext } from "contexts/AppContext";

const MyProfile = () => {
  //const { data: user } = useGetIdentity();
   const { mutate } = useUpdate();
   const { currentUserData } = useAppContext();

  //console.log("profile user is", user);
  //const { data, isLoading, isError } = useOne({
    //resource: "users",
    //id: user?.userid,
  //});
  //console.log("retrieved data is", data);

 //const myProfile = data?.data ?? {};
 const myProfile = currentUserData;
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: myProfile.name,
    firstName: myProfile.firstName,
    lastName: myProfile.lastName,
    avatar: myProfile.avatar,
  });
  console.log(formData.firstName)
  const handleEdit = () => {
    setEditing(!editing);
  };

  const handleInputChange = (e : any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    const tempUser = formData;
    mutate({
      resource: "users",
      values: { tempUser
      },
      //id: user.userid
      id: myProfile._id 
    }, {
    onSuccess: (data, variables, context) => {
      //setCurrentUserData(tempUser)
      console.log("success")
     }
    });
  }; //Need to update context APi

  //if (isLoading) return <div>Loading...</div>;
  //if (isError) return <div>Error...</div>;

  return (
    <div>
      {editing ? (
        <div>
          {/* Edit form */}
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <label>firstName</label>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
          />
          <label>lastName</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
          />
          <label>Avatar</label>
          <input
            type="text"
            name="avatar"
            value={formData.avatar}
            onChange={handleInputChange}
          />

          <button onClick={handleSave}>Save</button>
        </div>
      ) : (
        <Profile
          type="My"
          firstName={myProfile.firstName}
          lastName={myProfile.lastName}
          email={myProfile.email}
          avatar={myProfile.avatar}
          isTutor={false}
        />
      )}

      <button onClick={handleEdit}>Edit</button>
    </div>
  );
};

export default MyProfile