/**
 * SelectSubject Component
 * Provides interface for selecting subjects and initiating tutoring sessions
 * Handles both scheduled and immediate booking options
 */
import { useNavigate } from "react-router-dom";
import { Button } from 'components/ui/button';
import { Subject, SubjectSelectProps } from 'interfaces/tutor';
import React, { useState } from 'react';

const SelectSubject = ({ tutor }: SubjectSelectProps) => {
  // Initialize navigation hook for routing
  const navigate = useNavigate();

  // State for tracking selected subject and its details
  const [selectedSubject, setSelectedSubject] = useState<Subject>({ name: '', price: '' });

  /**
   * Handles navigation to booking page
   * @param t - Tutor data
   * @param isImmediate - Whether this is an immediate booking
   */
  const handleClick = (t: any, isImmediate: boolean) => {
    // Prepare state for booking route
    const state = { tutorToBeBooked: t, isImmediate, selectedSubject };
    // Navigate to booking creation page with state
    navigate(`/booking/create/${t._id}`, { state });
  };

  /**
   * Handles subject selection from dropdown
   * Updates selected subject state with name and price
   * @param event - Select element change event
   */
  const handleSubjectSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedSubject = event.target.value;
    // Find selected subject details from tutor's subjects
    const foundSubject = tutor.subjects.find((subject) => subject.name === newSelectedSubject);
    if (foundSubject) {
      setSelectedSubject(foundSubject);
    }
  };

  return (
    <div className="flex flex-col gap-4 items-center">
      {/* Subject Selection Dropdown */}
      <label className="text-base md:text-lg lg:text-xl xl:text-2xl font-bold mb-2 flex flex-col items-center">
        Select a Subject
        <div className="w-full max-w-md">
          <select
            value={selectedSubject.name}
            onChange={handleSubjectSelect}
            className="mt-2 w-full border border-gray-300 rounded-md px-2 text-lg md:text-xl lg:text-2xl mx-auto"
          >
            {/* Empty default option */}
            <option value="" style={{ fontSize: '1rem', height: '1.5rem' }}></option>
            {/* Map through tutor's subjects */}
            {tutor.subjects.map((subject: Subject) => (
              <option key={subject.name} value={subject.name} style={{ fontSize: '1rem' }}>
                {subject.name}
              </option>
            ))}
          </select>
          {/* Display selected subject's price */}
          <p className="mt-2 md:ml-4 text-lg">Hourly rate $ {selectedSubject.price}</p>
        </div>
      </label>

      {/* Booking Action Buttons */}
      <div className="flex flex-col gap-2">
        {/* Schedule Tutor Button */}
        <Button
          variant="default"
          size="default"
          className="bg-primary text-primary-foreground hover:bg-primary/90"
          onClick={() => handleClick(tutor, false)}
          disabled={false}
        >
          Schedule Tutor
        </Button>

        {/* Instant Booking Button - Only enabled if tutor is active */}
        <Button
          variant="default"
          size="default"
          className={tutor.isActive ? 'bg-primary text-primary-foreground hover:bg-primary/90' : 'bg-ccc text-666'}
          onClick={() => tutor.isActive && handleClick(tutor, true)}
          disabled={!tutor.isActive}
        >
          Instant Booking
        </Button>
      </div>
    </div>
  );
};

export default SelectSubject;