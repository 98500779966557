/**
 * Application Component
 * Displays and manages individual tutor applications
 * Includes application details, subject information, and approval controls
 */
import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { ApplicationProps, Subject } from 'interfaces/tutor';
import { useAppContext } from 'contexts/AppContext';
import { Button } from 'components/ui/button';
import { useUpdate } from "@refinedev/core";

const Application = ({ 
  id, 
  number, 
  description, 
  degree, 
  subjects, 
  country, 
  approved 
}: ApplicationProps) => {
  // Get current user data from context
  const { currentUserData } = useAppContext();
  
  // Parse subjects array from props
  const subjectsArrayOfObjects: Subject[] = subjects as Subject[];
  
  // Initialize navigation and update hooks
  const navigate = useNavigate();
  const { mutate } = useUpdate();

  /**
   * Truncates text to a specified number of words
   * @param text - The text to truncate
   * @param maxWords - Maximum number of words to show
   * @returns Truncated text with ellipsis
   */
  const truncateText = (text: string, maxWords: number) => {
    const words = text.split(' ');
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    }
    return text;
  };

  /**
   * Handles accepting a tutor application
   * Updates application status to approved
   */
  const handleAcceptApplication = () => {
    if (!id) return;
    
    const tempApplication: ApplicationProps = {
      id,
      number,
      description,
      degree,
      subjects: JSON.stringify(subjects), // Stringify subjects for storage
      country,
      approved: true
    };
   
    mutate({
      resource: 'tutor-application',
      values: {
        tempApplication,
      },
      id,
    });
  };

  /**
   * Handles denying a tutor application
   * Updates application status to not approved
   */
  const handleDenyApplication = () => {
    if (!id) return;
   
    const tempApplication: ApplicationProps = {
      id,
      number,
      description,
      degree,
      subjects: JSON.stringify(subjects),
      country,
      approved: false
    };
   
    mutate({
      resource: 'tutor-application',
      values: {
        tempApplication,
      },
      id,
    });
  };

  // Show error state if no ID is provided
  if (!id) {
    return <div>Invalid application ID</div>;
  }

  return (
    <div className="flex items-start p-4 hover:shadow-md transition-all duration-300 w-full border border-gray-300 rounded-md bg-gray-200 mx-auto">
      {/* Application Details Column */}
      <div className="flex flex-col justify-between mr-4 ml-4 w-3/5">
        {/* Application Header with ID and Status */}
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold text-blue-900">
            <Link to={`/applications/${id}`}>Application ID: {id}</Link>
          </h2>
          <span className={`mt-2 ${approved ? 'text-green-500' : 'text-red-500'}`}>
            {approved ? (
              <>
                Approved
                &#10004;
              </>
            ) : (
              <>
                Not Approved
                &#10006;
              </>
            )}
          </span>
        </div>
       
        {/* Contact Information */}
        <p className="text-sm text-gray-600">Phone Number: {number}</p>
       
        {/* Application Description */}
        <p className="text-sm text-gray-600 min-h-[40px] max-w-[75%]">
          Description: {truncateText(description, window.innerWidth < 768 ? 12 : 35)}
        </p>
       
        {/* Academic Information */}
        <p className="text-sm text-gray-600">Degree: {degree}</p>
       
        {/* Location Information */}
        <p className="text-sm text-gray-600">Country: {country}</p>
      </div>

      {/* Subjects and Actions Column */}
      <div className="flex flex-col">
        {/* Subjects List */}
        <h3 className="text-sm text-gray-600 mb-2">Subjects:</h3>
        <div className="flex flex-col space-y-2">
          {subjectsArrayOfObjects.map((subject, index) => (
            <div
              key={index}
              className={`px-2 py-1 border border-gray-300 rounded-md`}
            >
              {subject.name} - ${subject.price}/hr
            </div>
          ))}
        </div>

        {/* Action Buttons */}
        <div className="flex flex-col items-center md:flex-row md:justify-center md:gap-4 space-y-2 mt-4">
          {/* Accept Button */}
          <Button
            variant="default"
            size="default"
            className="bg-green-500 text-white hover:bg-green-600"
            onClick={handleAcceptApplication}
            disabled={approved}
          >
            Accept
          </Button>

          {/* Deny Button */}
          <Button
            variant="default"
            size="default"
            className="bg-red-500 text-white hover:bg-red-600"
            onClick={handleDenyApplication}
            disabled={!approved}
          >
            Deny
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Application;