import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useAppContext } from 'contexts/AppContext';
import { useUpdate } from "@refinedev/core";

const StudentSettings = () => {
    const { currentUserData, setCurrentUserData } = useAppContext();
    const [isEditing, setIsEditing] = useState(false);
    const [newFirstName, setNewFirstName] = useState(currentUserData.firstName);
    const [newLastName, setNewLastName] = useState(currentUserData.lastName);
    const { mutate } = useUpdate();

    const onEditClick = () => {
        setIsEditing(true);
    };

    const handleDoneClick = () => {
        const tempUser = { ...currentUserData };
        tempUser.firstName = newFirstName;
        tempUser.lastName = newLastName;

        mutate({
            resource: "users",
            values: { tempUser },
            id: currentUserData?._id
        }, {
            onSuccess: (data, variables, context) => {
                setCurrentUserData(tempUser);
            }
        });

        setIsEditing(false);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
    };

    return (
        <div className="p-4 max-w-xl mx-auto">
            {isEditing ? (
                <div>
                    <TextField
                        label="First Name"
                        variant="outlined"
                        type="text"
                        value={newFirstName}
                        onChange={(e) => setNewFirstName(e.target.value)}
                        fullWidth
                        className="mb-2"
                    />
                    <TextField
                        label="Last Name"
                        variant="outlined"
                        type="text"
                        value={newLastName}
                        onChange={(e) => setNewLastName(e.target.value)}
                        fullWidth
                        className="mb-2"
                    />
                </div>
            ) : (
                <div>
                    <p className="mb-2">{currentUserData.firstName}</p>
                    <p className="mb-2">{currentUserData.lastName}</p>
                </div>
            )}

            <Button
                variant="contained"
                color="primary"
                onClick={isEditing ? handleDoneClick : onEditClick}
                fullWidth
                className="mb-2"
            >
                {isEditing ? "Done" : "Edit"}
            </Button>
            {isEditing && (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancelClick}
                    fullWidth
                >
                    Cancel
                </Button>
            )}
        </div>
    );
};

export default StudentSettings;
