import React from "react";

const Balance = ({ balance }: any) => {
  const isNegativeBalance = balance < 0;
  const displayBalance = Math.abs(balance);

  const balanceText = () => {
    if (balance > 0) {
      return <div className="justify-end">You can pay your balance now, or at your next booking!</div>;
    } else if (isNegativeBalance) {
      return (
        <div className="text-gray-500 justify-end">
          Your account has a credit which will be applied at the next booking!
        </div>
      );
    } else {
      return null; 
    }
  };

  return (
    <div className="mt-20 rounded p-6 flex flex-col items-end">
      <div
        style={{
          fontSize: 25,
          fontWeight: 700,
          color: isNegativeBalance ? "rgba(17, 20, 45, 0.5)" : "#11142D",
        }}
      >
        Balance: {isNegativeBalance ? `-$${displayBalance}` : `$${displayBalance}`}
      </div>
      <button
        className={`mt-2 p-2 rounded ${
          balance <= 0 ? "bg-gray-300 text-gray-500 cursor-not-allowed" : "bg-blue-500 text-white hover:bg-blue-600 hover:shadow"
        }`}
        disabled={balance <= 0}
      >
        Pay Now
      </button>
      {balanceText()}
    </div>
  );
};

export default Balance;
