/**
 * TutorProfile Component
 * Displays detailed information about a specific tutor's profile
 * Includes personal info, subjects taught, and booking capabilities
 */
import { useOne } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { Profile } from 'components';
import SelectSubject from "components/tutor/SelectSubject";

const TutorProfile = () => {
  // Extract tutor ID from URL parameters
  const { id } = useParams();

  // Fetch tutor data using Refine's useOne hook
  // This retrieves detailed information about the specific tutor
  const { data, isLoading, isError} = useOne({
    resource:'tutors', 
    id: id as string
  })

  // Log tutor data for debugging
  console.log("data is ", data);
  
  // Process tutor data by parsing JSON strings for subjects and schedule
  // The ?? operator provides empty arrays as fallbacks if parsing fails
  const tutorToBeBooked: any = {
    ...data?.data,
    subjects: data?.data?.subjects ? JSON.parse(data.data.subjects) : [],
    schedule: data?.data?.schedule ? JSON.parse(data.data.schedule) : [],
  };

  // Log processed tutor data
  console.log(tutorToBeBooked);

  // Show loading state while data is being fetched
  if(isLoading) return <div>Loading...</div>
  
  // Show error state if data fetch failed
  if(isError) return <div>Error...</div>

  // Render the tutor's profile using the Profile component
  return (
    <Profile 
      type="Tutor" // Indicates this is a tutor profile view
      firstName={tutorToBeBooked.User.firstName}
      lastName={tutorToBeBooked.User.lastName}
      email={tutorToBeBooked.User.email}
      avatar={tutorToBeBooked.User.avatar}
      subjects={tutorToBeBooked.subjects} // Array of subjects taught
      description={tutorToBeBooked.tutorDescription} // Tutor's bio/description
      rating={tutorToBeBooked.rating} // Tutor's rating
      isTutor={true} // Flags this as a tutor profile
      tutorToBeBooked={tutorToBeBooked} // Full tutor data for booking
    />
  )
}

export default TutorProfile;