/**
 * TutorApplication Component
 * Handles the entire tutor application process including form submission,
 * resume upload, and automatic tutor profile creation upon approval
 */
//@ts-nocheck // Disables TypeScript checks for this file
import { useState } from "react";
import { useDelete, useGetIdentity } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { FieldValues } from "react-hook-form";
import Form from 'components/common/Form';

const TutorApplication = () => {
  // Get current user's identity from Refine auth provider
  const { data: user } = useGetIdentity({
    v3LegacyAuthProviderCompatible: true // Ensures compatibility with v3 legacy auth providers
  });

  // State for managing resume file data
  // Stores both the name and URL of the uploaded resume
  const [resume, setResume] = useState({name: '', url: ''});

  // Initialize form handling with Refine's useForm hook
  // Provides form state management and submission handling
  const { refineCore: {onFinish, formLoading }, register, handleSubmit} = useForm();

  // Hook for handling deletion operations
  const {mutate} = useDelete();

  /**
   * Handles resume file upload and conversion to base64
   * @param file - The uploaded resume file
   */
  const handleResumeChange = (file: File) => {
    // Creates a Promise to read the file as base64 data URL
    const reader = (readFile: File) => new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result as string);
      fileReader.readAsDataURL(readFile);
    });

    // Process the file and update resume state
    reader(file).then((result: string) => setResume({ name: file?.name, url: result }));
  };

  /**
   * Handles form submission and tutor profile creation
   * @param data - Form field values
   */
  const onFinishHandler = async (data: FieldValues) => {
    // Validate resume upload
    if(!resume.name) return alert('Please upload a resume');

    // Process selected subjects and their prices
    const selectedNames = data.subjects
      .filter((subject: { selected: any; }) => subject.selected)
      .map((selectedSubject: { name: any; }) => selectedSubject.name);

    const selectedPrices = data.subjects
      .filter((subject: { selected: any; }) => subject.selected)
      .map((selectedSubject: { price: any; }) => selectedSubject.price);

    // Convert arrays to strings for storage
    const namesString = selectedNames.join(' ');
    const pricesString = selectedPrices.join(' ');

    // Validate subject selection
    if (namesString && pricesString) {
      console.log('Selected Names:', namesString);
      console.log('Selected Prices:', pricesString);
    } else {
      return alert('No selected subjects.');
    }

    // Create array of subject objects with names and prices
    const subjectsArrayOfObjects = createSubjectsArrayOfObjects(namesString, pricesString)
    data.subjects = subjectsArrayOfObjects;

    // Submit application data
    await onFinish({ ...data, resume: resume.url, userId: user.userid })

    // Fetch student data for the applying user
    const getStudentRequest = await fetch(`${process.env.REACT_APP_SERVER}/api/v1/students/${user.userid}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })

    const student = await getStudentRequest.json();
     
    // Initialize empty schedule array for new tutor
    const schedule = [{}];

    // Create tutor profile automatically on application submission
    const createTutor = await fetch(`${process.env.REACT_APP_SERVER}/api/v1/tutors`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userId: user.userid,
        subjects: subjectsArrayOfObjects,
        rating: 5.0, // Default initial rating
        tutorDescription: data.description,
        isActive: false, // Initially inactive
        schedule: schedule
      })
    })

    const tutor = await createTutor.json();
    
    // Delete student profile if it exists (converting to tutor)
    mutate({
      resource: "students",
      id: student._id
    },
    {
      onSuccess: () => {
        console.log("success");
      },
    });
  }

  /**
   * Creates an array of subject objects from separate name and price strings
   * @param subjects - Space-separated string of subject names
   * @param prices - Space-separated string of corresponding prices
   * @returns Array of subject objects with name and price properties
   */
  function createSubjectsArrayOfObjects(subjects: string, prices: string) {
    const subjectsArray = subjects.split(" ");
    const pricesArray = prices.split(" ")

    // Validate array lengths match
    if (subjectsArray.length !== pricesArray.length) {
      throw new Error('Arrays must have the same length');
    }

    // Create array of subject objects
    const result = [];
    for (let i = 0; i < subjectsArray.length; i++) {
      const obj = {
        name: subjectsArray[i],
        price: pricesArray[i],
      };
      result.push(obj);
    }
  
    return result;
  }

  // Render the application form
  return (
    <Form
      type="Create"
      register={register}
      onFinish={onFinish}
      formLoading={formLoading}
      handleSubmit={handleSubmit}
      handleResumeChange={handleResumeChange}
      onFinishHandler={onFinishHandler}
      resume={resume}
    />
  )
}

export default TutorApplication;