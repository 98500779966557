/**
 * Application Constants
 * Defines navigation links and default values for various user roles
 */

/**
 * Navigation links for tutor users
 * Defines available routes and their properties
 */
export const tutorLinks = [
    {
      label: 'Dashboard',     // Display text
      route: '/',            // URL route
    },
    {
      label: 'Book A Tutor',
      route: '/book-a-tutor',
    },
    {
      label: 'FAQ',
      route: '/FAQ',
    },
    {
      label: 'Home',
      route: '/home',
    },
    {
      label: 'Our Approach',
      route: '/our-approach',
    },
]

/**
 * Navigation links for student users
 * Includes all tutor links plus additional student-specific routes
 */
export const studentLinks = [
    {
      label: 'Dashboard',
      route: '/',
    },
    {
      label: 'Book A Tutor',
      route: '/book-a-tutor',
    },
    {
      label: 'FAQ',
      route: '/FAQ',
    },
    {
      label: 'Home',
      route: '/home',
    },
    {
      label: 'Our Approach',
      route: '/our-approach',
    },
    {
      label: 'Tutor Application',
      route: '/tutor-application',
    },
]

/**
 * Navigation links for non-authenticated visitors
 * Limited set of accessible routes
 */
export const visitorLinks = [
    {
      label: 'Book A Tutor',
      route: '/book-a-tutor',
    },
    {
      label: 'FAQ',
      route: '/FAQ',
    },
    {
      label: 'Home',
      route: '/home',
    },
    {
      label: 'Our Approach',
      route: '/our-approach',
    },
    {
      label: 'Tutor Application',
      route: '/tutor-application',
    },
    {
      label: 'Sign In',
      route: '/sign-in'
    }
]

/**
 * Navigation links for admin users
 * Includes administrative routes and controls
 */
export const adminLinks = [
    {
      label: 'Admin Dashboard',
      route: '/admin-dashboard',
    },
    {
      label: 'Book A Tutor',
      route: '/book-a-tutor',
    },
    {
      label: 'FAQ',
      route: '/FAQ',
    },
    {
      label: 'Home',
      route: '/home',
    },
    {
      label: 'Our Approach',
      route: '/our-approach',
    },
    {
      label: 'Tutor Application',
      route: '/tutor-application',
    },
]

/**
 * Default values for event creation
 * Used as initial state for event forms
 */
export const eventDefaultValues = {
    title: '',                 // Event title
    description: '',          // Event description
    location: '',            // Event location
    imageUrl: '',           // Event image URL
    startDateTime: new Date(),  // Event start time
    endDateTime: new Date(),    // Event end time
    categoryId: '',            // Event category
    price: '',               // Event price
    isFree: false,          // Whether event is free
    url: '',               // Event URL
}