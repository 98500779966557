/**
 * TutorSettings Component
 * Manages tutor profile settings, subjects, pricing, and profile information
 * Provides interface for tutors to update their teaching preferences and personal details
 */
import React, { useState } from 'react';
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { makeValidJSON } from 'functions/common';
import { useAppContext } from 'contexts/AppContext';
import { useUpdate } from "@refinedev/core";
import { Button } from '../ui/button';

interface SubjectWithPrice {
    name: string;      // Subject name
    price: string;    // Price per hour
}

const TutorSettings = () => {
    // Get global context values and update mutation
    const { currentTutorData, currentUserData, setCurrentTutorData, setCurrentUserData } = useAppContext();
    
    // State management
    const [subjects, setSubjects] = useState<SubjectWithPrice[]>(currentTutorData.subjects);
    const [newSubject, setNewSubject] = useState<string>('');
    const [newPrice, setNewPrice] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [newFirstName, setNewFirstName] = useState<string>(currentUserData.firstName);
    const [newLastName, setNewLastName] = useState<string>(currentUserData.lastName);
    const [newTutorDescription, setNewTutorDescription] = useState<string>(currentTutorData.tutorDescription);
    
    // Update mutation hook
    const { mutate } = useUpdate();
    
    /**
     * Handles price changes for existing subjects
     * @param index - Index of subject being modified
     * @param newPrice - Updated price value
     */
    const handleInputEdit = (index: number, newPrice: number): void => {
        const updatedSubjects = [...subjects];
        updatedSubjects[index].price = newPrice.toString();
        setSubjects(updatedSubjects);
    };
  
    /**
     * Enables edit mode for profile
     */
    const onEditClick = (): void => {
        setIsEditing(true);
    };
  
    /**
     * Saves all profile changes
     * Updates both tutor and user data in database
     */
    const handleDoneClick = (): void => {
        // Prepare tutor data update
        const tempTutor = {
            ...currentTutorData,
            subjects: JSON.stringify(subjects),
            tutorDescription: newTutorDescription,
            schedule: JSON.stringify(currentTutorData.schedule)
        };

        // Prepare user data update
        const tempUser = {
            ...currentUserData,
            firstName: newFirstName,
            lastName: newLastName
        };

        // Update tutor data
        mutate({
            resource: "tutors",
            values: { tempTutor },
            id: currentTutorData?._id
        }, {
            onSuccess: (data, variables, context) => {
                setCurrentTutorData(tempTutor);
            }
        });

        // Update user data
        mutate({
            resource: "users",
            values: { tempUser },
            id: currentUserData?._id
        }, {
            onSuccess: (data, variables, context) => {
                setCurrentUserData(tempUser);
            }
        });

        // Exit edit mode
        setIsEditing(false);
    };
  
    /**
     * Adds a new subject with price
     */
    const onAddClick = (): void => {
        if (!newSubject || !newPrice) {
            alert('Please enter both subject name and price');
            return;
        }

        const newSubjects = [...subjects, { 
            name: newSubject, 
            price: newPrice 
        }];
        setSubjects(newSubjects);
        setNewSubject('');
        setNewPrice('');
    };
  
    /**
     * Cancels editing mode without saving changes
     */
    const handleCancelClick = (): void => {
        setIsEditing(false);
        setNewSubject('');
        setNewPrice('');
        // Reset to original values
        setNewFirstName(currentUserData.firstName);
        setNewLastName(currentUserData.lastName);
        setNewTutorDescription(currentTutorData.tutorDescription);
    };
  
    /**
     * Removes a subject from the list
     * @param index - Index of subject to remove
     */
    const handleDeleteClick = (index: number): void => {
        const updatedSubjects = [...subjects];
        updatedSubjects.splice(index, 1);
        setSubjects(updatedSubjects);
    };

    return (
        <div className="p-4 max-w-xl mx-auto">
            {/* Profile Information Section */}
            <div className="mb-4">
                <h2 className="text-xl font-bold mb-4">Profile Information</h2>
                
                {isEditing ? (
                    // Edit Mode
                    <div>
                        {/* First Name Input */}
                        <label className="block text-sm font-bold mb-2">First Name:</label>
                        <input
                            type="text"
                            className="border border-gray-300 rounded-md p-2 w-full mb-2"
                            value={newFirstName}
                            onChange={(e) => setNewFirstName(e.target.value)}
                        />

                        {/* Last Name Input */}
                        <label className="block text-sm font-bold mb-2">Last Name:</label>
                        <input
                            type="text"
                            className="border border-gray-300 rounded-md p-2 w-full mb-2"
                            value={newLastName}
                            onChange={(e) => setNewLastName(e.target.value)}
                        />

                        {/* Description Input */}
                        <label className="block text-sm font-bold mb-2">Description:</label>
                        <textarea
                            className="border border-gray-300 rounded-md p-2 w-full mb-4"
                            value={newTutorDescription}
                            onChange={(e) => setNewTutorDescription(e.target.value)}
                            rows={4}
                        />
                    </div>
                ) : (
                    // View Mode
                    <div>
                        <label className="block text-sm font-bold mb-2">First Name:</label>
                        <input
                            type="text"
                            className="border border-gray-300 rounded-md p-2 w-full mb-2"
                            value={newFirstName}
                            readOnly
                        />
                        <label className="block text-sm font-bold mb-2">Last Name:</label>
                        <input
                            type="text"
                            className="border border-gray-300 rounded-md p-2 w-full mb-2"
                            value={newLastName}
                            readOnly
                        />
                        <label className="block text-sm font-bold mb-2">Description:</label>
                        <textarea
                            className="border border-gray-300 rounded-md p-2 w-full mb-4"
                            value={newTutorDescription}
                            readOnly
                            rows={4}
                        />
                    </div>
                )}
            </div>

            {/* Subjects Section */}
            <div>
                <h2 className="text-xl font-bold mb-4">Subject Management</h2>
                
                {/* Current Subjects List */}
                <div className="mb-6">
                    <h3 className="text-lg font-semibold mb-2">Your Subjects:</h3>
                    {subjects.map((subject, index) => (
                        <div 
                            key={index} 
                            className="border border-gray-300 rounded-md p-2 mb-2 flex justify-between items-center"
                        >
                            <span className="text-base font-semibold">{subject.name}</span>
                            <div className="flex items-center gap-4">
                                {isEditing ? (
                                    <input
                                        type="number"
                                        className="border border-gray-300 rounded-md p-2 w-20"
                                        value={subject.price}
                                        onChange={(e) => handleInputEdit(index, parseFloat(e.target.value))}
                                    />
                                ) : (
                                    <span className="text-base">${subject.price}/hr</span>
                                )}
                                {isEditing && (
                                    <button 
                                        className="text-red-500 hover:text-red-700"
                                        onClick={() => handleDeleteClick(index)}
                                    >
                                        <DeleteIcon />
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Add New Subject Form */}
                {isEditing && (
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold mb-2">Add New Subject</h3>
                        <div className="flex gap-2 mb-4">
                            <input
                                type="text"
                                className="border border-gray-300 rounded-md p-2 flex-grow"
                                placeholder="Subject Name"
                                value={newSubject}
                                onChange={(e) => setNewSubject(e.target.value)}
                            />
                            <input
                                type="number"
                                className="border border-gray-300 rounded-md p-2 w-24"
                                placeholder="Price"
                                value={newPrice}
                                onChange={(e) => setNewPrice(e.target.value)}
                            />
                            <button
                                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                                onClick={onAddClick}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                )}

                {/* Action Buttons */}
                <div className="flex gap-4">
                    <button
                        className={`px-4 py-2 rounded-md w-full ${
                            isEditing 
                                ? 'bg-green-500 hover:bg-green-600' 
                                : 'bg-blue-500 hover:bg-blue-600'
                        } text-white`}
                        onClick={isEditing ? handleDoneClick : onEditClick}
                    >
                        {isEditing ? "Save Changes" : "Edit Profile"}
                    </button>

                    {isEditing && (
                        <button
                            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 w-full"
                            onClick={handleCancelClick}
                        >
                            Cancel
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TutorSettings;