/**
 * Separator Component
 * A visual divider that separates content using Radix UI Primitive
 * Supports both horizontal and vertical orientations
 */
"use client"
import * as React from "react"
import * as SeparatorPrimitive from "@radix-ui/react-separator"
import { cn } from "../../utils/cn"

/**
 * Enhanced Separator Component
 * @param className - Additional CSS classes
 * @param orientation - Direction of separator (horizontal/vertical)
 * @param decorative - Whether separator is purely decorative
 * @param props - Additional component props
 * @param ref - Reference to separator element
 */
const Separator = React.forwardRef<
  // Type definitions for ref and props
  React.ElementRef<typeof SeparatorPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root>
>(
  (
    { 
      className, 
      orientation = "horizontal", // Default to horizontal orientation
      decorative = true, // Default to decorative (no semantic meaning)
      ...props 
    },
    ref
  ) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      // Combine default classes with any provided classes
      className={cn(
        "shrink-0 bg-border", // Base styles
        // Conditional styling based on orientation
        orientation === "horizontal" 
          ? "h-[1px] w-full"    // Horizontal separator styles
          : "h-full w-[1px]",   // Vertical separator styles
        className
      )}
      {...props}
    />
  )
)

// Set display name for debugging purposes
Separator.displayName = SeparatorPrimitive.Root.displayName

export { Separator }