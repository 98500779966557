import React from 'react'
import { Box } from "@mui/material";
import { BookingForm } from "components";
import { useForm } from "@refinedev/react-hook-form";
import { FieldValues } from "react-hook-form";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { TutorProfileProps } from 'interfaces/common';
import { useAppContext } from '../contexts/AppContext';

const Booking = () => {

  const { currentStudentData } = useAppContext();
  const { refineCore: {onFinish, formLoading }, register, handleSubmit} = useForm();
  const location = useLocation();
  const state = location.state as any;
  const isImmediate = state.isImmediate || false;
  const tutorToBeBooked = state.tutorToBeBooked as TutorProfileProps;
  const selectedSubject = state.selectedSubject;
  console.log("tutor about to be booked" , tutorToBeBooked);
  // this generates basic available timeslots for the next 6 days

  const onFinishHandler = async (data: FieldValues) => {
    //check for empty fields
   console.log("data is", data)
    //sendEmail();
    var studentName = (currentStudentData != null) ? currentStudentData.User.firstName : "Temp Student"
    const subjectObject = tutorToBeBooked.subjects.find(subject => subject.name === data.subject.name);
    console.log("subject object is", subjectObject);
    //Begin Stripe payment
    axios.post(`${process.env.REACT_APP_SERVER}/api/v1/create-checkout-session`, {
      name: `${tutorToBeBooked.User.firstName}'s ${data.subject.name} Session with ${studentName}`, 
      subject: subjectObject, //Contains price and subject. ->  .Subject now contains just name
      duration: timeToMinutes(data.duration),
      startTime: data.startTime,
      date: data.date,
      studentId: currentStudentData?._id,
      studentEmail : currentStudentData?.User.email ,
      tutorId: tutorToBeBooked._id,
      tutorEmail: tutorToBeBooked.User.email

  }, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((response) => {
    // Handle the response from the API
    if (response.status === 200) {
      // Payment session created successfully, redirect the user to the checkout page
      console.log("response is ok", response.data.url);
      window.location.href = response.data.url;
    } else {
      // Handle the error case
      console.error('Error creating payment session');
    }
  })
  .catch((error) => {
    console.error('Error creating payment session:', error);
  });

    //Replaced with creating a booking on the server end
   // await onFinish({...data, studentId: currentStudentData?._id, tutorId: tutorToBeBooked._id});
  };

  function timeToMinutes(timeString : string) {
    // Split the time string into hours, minutes, and seconds
    var timeArray = timeString.split(':');

    // Convert hours, minutes, and seconds to integers
    var hours = parseInt(timeArray[0], 10);
    var minutes = parseInt(timeArray[1], 10);
    var seconds = parseInt(timeArray[2], 10);

    // Calculate the total minutes
    var totalMinutes = hours * 60 + minutes + seconds / 60;

    // Convert to an integer
    var minutesAsInteger = Math.floor(totalMinutes);

    return minutesAsInteger;
}

function minutesToTime(minutes : any) {
  // Ensure that minutes is a non-negative integer
  if (!Number.isInteger(minutes) || minutes < 0) {
      throw new Error("Input must be a non-negative integer.");
  }

  // Calculate hours, minutes, and seconds
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = minutes % 60;
  var seconds = 0; // Assuming seconds is always 0 for simplicity

  // Format the time object
  var timeObject = `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  return timeObject;
}

  return (
      <BookingForm
          type="Create"
          tutorInfo={tutorToBeBooked}
          register={register}
          onFinish={onFinish}
          formLoading={formLoading}
          handleSubmit={handleSubmit}
          //handleImageChange={handleImageChange}
          onFinishHandler={onFinishHandler}
          isImmediate = {isImmediate}
          subject = {selectedSubject}
          //propertyImage={propertyImage}
        />
  )
}

export default Booking