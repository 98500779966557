/**
 * Footer Component
 * Provides a consistent footer section across all pages
 * Contains branding information and attribution
 */
import React from "react";
import { Box, AppBar, Toolbar, Typography } from "@mui/material";

export const Footer: React.FC = () => {
  // Always render footer for consistency across app
  const shouldRenderHeader = true;

  return shouldRenderHeader ? (
    // AppBar component for fixed positioning and elevation
    <AppBar 
      position="sticky"  // Stays at bottom of viewport
      elevation={1}      // Subtle shadow for depth
      sx={{ backgroundColor: "#4c5aa3" }}  // Custom background color
    >
      {/* Toolbar for content layout */}
      <Toolbar>
        {/* Container for centered content */}
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          textAlign="center"
        >
          {/* Footer text content */}
          <Typography variant="body1" fontWeight="bold" color="white">
            STEMConnects is part of STEM Academy For Young Kids and iGo STEM Academy
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  ) : null;
};