import React from 'react';
import { Button } from '../components/ui/button'

const Homepage = () => {
  return (
    <>
      <section className="bg-primary-50 bg-contain py-5 md:py-10">
        <div className="wrapper grid grid-cols-1 gap-5 md:grid-cols-2 2xl:gap-0">
          <div className="flex flex-col justify-center gap-8">
            <h1 className="h1-bold">One on One : Online Tutoring </h1>
            <p className="p-regular-20 md:p-regular-24">With the vision of learning through exploration and engagement, STEMConnects brings a way to instantly and easily connect your child to our dedicated group of tutors. 
                    We care for your child's developmental goals and are committed to work with your child's individual learning needs. 
                    Our experienced educators are skilled and qualified individuals trained and capable of addressing students’ academic and intellectual challenges.</p>
            <Button size="lg" asChild className="button w-full sm:w-fit">
              <a href="/book-a-tutor">
                Find A Tutor
              </a>
            </Button>
          </div>

          <img 
            src="/assets/images/hero.png"
            alt="hero"
            width={1000}
            height={1000}
            className="max-h-[70vh] object-contain object-center 2xl:max-h-[50vh]"
          />
        </div>
      </section> 

      <section className="bg-gray-100 py-5 md:py-10">
  <div className="wrapper grid grid-cols-1 gap-5 md:grid-cols-2">
    {/* Column 1 */}
    <div className="flex flex-col items-center mb-5 md:mb-0">
      {/* Image 1 */}
      <img
        src="/assets/images/1ON1.png"
        alt=""
        className="w-full h-64 object-cover mb-3"
      />
      <p className="text-center p-regular-20 md:p-regular-24 mb-3">
      Interested in passing knowledge to others in your field of expertise?
                     Becoming a tutor on STEMConnects is a smooth path to giving the gift of knowledge to others. Apply to become a Tutor to start the process.
      </p>
      <Button size="lg" asChild className="button w-full sm:w-fit">
        <a href="/FAQ">
          View FAQ
        </a>
      </Button>
    </div>
    <div className="flex flex-col items-center">
      {/* Image 2 */}
      <img
        src="/assets/images/CheckBoard.png"
        alt=""
        className="w-full h-64 object-cover mb-3"
      />
      <p className="text-center p-regular-20 md:p-regular-24 mb-3">
      We have various tutors for subjects ranging from Mathematics, Language Arts, Sciences, and Computer Programming. 
                    Book a session with the tutor of your choosing! Tutors will differ in experience, rates, and availability!
      </p>
      <Button size="lg" asChild className="button w-full sm:w-fit">
        <a href="/book-a-tutor">
        Immediate Help
        </a>
      </Button>
    </div>

    {/* Column 2 */}
    <div className="flex flex-col items-center mb-5 md:mb-0">
      {/* Image 3 */}
      <img
        src="/assets/images/lightBulb.png"
        alt=""
        className="w-full h-64 object-cover mb-3"
      />
      <p className="text-center p-regular-20 md:p-regular-24 mb-3">
        As a very complex subject, Physics is a vast field in which our instructors can teach students who are beginners or more experienced
      </p>
      <Button size="lg" asChild className="button w-full sm:w-fit">
        <a href="/tutor-application">
        Become Tutor
        </a>
      </Button>
    </div>

    <div className="flex flex-col items-center">
      {/* Image 4 */}
      <img
        src="/assets/images/Schedule.png"
        alt=""
        className="w-full h-64 object-cover mb-3"
      />
      <p className="text-center p-regular-20 md:p-regular-24 mb-3">
      We have tutors available 24/7 to get you the help you need! Need urgent help for a test tomorrow? Stuck on a homework problem? Start an immediate session with one of our active tutors!
      </p>
      <Button size="lg" asChild className="button w-full sm:w-fit">
        <a href="/book-a-tutor">
        Schedule Tutor
        </a>
      </Button>
    </div>
  </div>
</section>

    </>
  );
};

export default Homepage;
